import React from 'react';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import './RangeSlider.css';

export default function Slider({ step, handleChange, unit, ...props }) {
	const onChange = (value) => {
		handleChange({ min: value[0], max: value[1] });
	};

	// console.log(props);

	return (
		<div className="range-slider-container">
			<RangeSlider step={step} onInput={onChange} {...props} />
			<div className="range-slider-unit-box">
				<div className="range-slider-value-box">
					<p>{props?.value[0]}</p>
					<span>{unit}</span>
				</div>

				<div className="range-slider-value-box">
					<p>{props?.value[1]}</p>
					<span>{unit}</span>
				</div>
			</div>
		</div>
	);
}
