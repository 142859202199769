import React from 'react';
import OfferCard from '../OfferCard';
import Houses from '../../media/images/houses.jpg';
import './CardsList.css';

export default function CardsList({ dataList }) {
	return (
		<div className="cards-list-container">
			{dataList.map(
				({
					title,
					price,
					address,
					rooms,
					dataId,
					space,
					bathrooms,
					date,
				}) => (
					<OfferCard
						key={`offer-card-${title}-${dataId}`}
						price={price}
						title={title}
						src={Houses}
						rooms={rooms}
						date={date}
						address={address}
						space={space}
						bathrooms={bathrooms}
						id={dataId}
					/>
				)
			)}
		</div>
	);
}
