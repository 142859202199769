import React, { useState } from 'react';
import ChevronDown from '../../media/svg/chevron-down.svg';
import './FilterAccordion.css';

export default function FilterAccordion({ title = 'Accordion', children }) {
	const [open, setOpen] = useState(true);

	const toggleAccordion = () => {
		setOpen((prev) => !prev);
	};
	return (
		<div className="accordion-container">
			<h3 onClick={toggleAccordion} className="accordion-title">
				{title}
				<img
					alt="Accordion icon"
					className={`accordion-icon ${
						open && 'accordion-icon-active'
					}`}
					src={ChevronDown}
				/>
			</h3>
			{open && <div className="accordion-content">{children}</div>}
		</div>
	);
}
