export const MAP_OFFER_TYPES = [
	{
		title: 'الجميع',
		code: 'ALL',
		id: 0,
	},
	{
		title: 'شراء',
		code: 'BUY',
		id: 1,
	},
	{
		title: 'ايجار',
		code: 'RENT',
		id: 2,
	},
];

export const PROPERTY_TYPE = [
	{
		title: 'اراضي سكنية',
		code: 'SKN',
		id: 1,
	},
	{
		title: 'اراضي زراعية',
		code: 'ZRY',
		id: 2,
	},
	{
		title: 'دور| بيوت',
		code: 'BYT',
		id: 3,
	},
	{
		title: 'تجاري',
		code: 'TGY',
		id: 4,
	},
	{
		title: 'شقق',
		code: 'SHQ',
		id: 5,
	},
	{
		title: 'مزارع',
		code: 'MZA',
		id: 6,
	},
	{
		title: 'عمارات',
		code: 'EMA',
		id: 7,
	},
	{
		title: 'محلات',
		code: 'MHL',
		id: 8,
	},
	{
		title: 'اخرى',
		code: 'OTH',
		id: 7,
	},
];
