// import NOT_FOUND from '../media/images/not-found.jpg';

import A1_1 from '../media/images/A1-img/A1-1.jpg';
import A1_2 from '../media/images/A1-img/A1-2.jpg';
import A1_3 from '../media/images/A1-img/A1-3.jpg';

import A2_1 from '../media/images/A2-img/A2-1.jpeg';
import A2_2 from '../media/images/A2-img/A2-2.jpeg';
import A2_3 from '../media/images/A2-img/A2-3.jpeg';

import A3_1 from '../media/images/A3-img/A3-1.jpg';
import A3_2 from '../media/images/A3-img/A3-2.jpg';
import A3_3 from '../media/images/A3-img/A3-3.jpg';

import A4_1 from '../media/images/A4-img/A4-1.jpg';
import A4_2 from '../media/images/A4-img/A4-2.jpg';
import A4_3 from '../media/images/A4-img/A4-3.jpg';

import A5_1 from '../media/images/A5-img/A5-1.jpg';
import A5_2 from '../media/images/A5-img/A5-2.jpg';

import A6_1 from '../media/images/A6-img/A6-1.jpg';
import A6_2 from '../media/images/A6-img/A6-2.jpg';
import A6_3 from '../media/images/A6-img/A6-3.jpg';

import A7_1 from '../media/images/A7-img/A7-1.jpg';
import A7_2 from '../media/images/A7-img/A7-2.jpg';
import A7_3 from '../media/images/A7-img/A7-3.jpg';

import A8_1 from '../media/images/A8-img/A8-1.jpg';
import A8_2 from '../media/images/A8-img/A8-2.jpg';
import A8_3 from '../media/images/A8-img/A8-3.jpg';

import A9_1 from '../media/images/A9-img/A9-1.jpg';
import A9_2 from '../media/images/A9-img/A9-2.jpg';
import A9_3 from '../media/images/A9-img/A9-3.jpg';

import A10_1 from '../media/images/A10-img/A10-1.jpg';
import A10_2 from '../media/images/A10-img/A10-2.jpg';
import A10_3 from '../media/images/A10-img/A10-3.jpg';

import A11_1 from '../media/images/A11-img/A11-1.jpg';
import A11_2 from '../media/images/A11-img/A11-2.jpg';
import A11_3 from '../media/images/A11-img/A11-3.jpg';

import A12_1 from '../media/images/A12-img/A12-1.jpg';
import A12_2 from '../media/images/A12-img/A12-2.jpg';
import A12_3 from '../media/images/A12-img/A12-3.jpg';

import A13_1 from '../media/images/A13-img/A13-1.jpg';
import A13_2 from '../media/images/A13-img/A13-2.jpg';
import A13_3 from '../media/images/A13-img/A13-3.jpg';

import A14_1 from '../media/images/A14-img/A14-1.jpg';
import A14_2 from '../media/images/A14-img/A14-2.jpg';
import A14_3 from '../media/images/A14-img/A14-3.jpg';

import A15_1 from '../media/images/A15-img/A15-1.jpg';
import A15_2 from '../media/images/A15-img/A15-2.jpg';
import A15_3 from '../media/images/A15-img/A15-3.jpg';

import A16_1 from '../media/images/A16-img/A16-1.jpg';
import A16_2 from '../media/images/A16-img/A16-2.jpg';
import A16_3 from '../media/images/A16-img/A16-3.jpg';

import A17_1 from '../media/images/A17-img/A17-1.jpg';
import A17_2 from '../media/images/A17-img/A17-2.jpg';
import A17_3 from '../media/images/A17-img/A17-3.jpg';

import A18_1 from '../media/images/A18-img/A18-1.jpg';
import A18_2 from '../media/images/A18-img/A18-2.jpg';
import A18_3 from '../media/images/A18-img/A18-3.jpg';

import A19_1 from '../media/images/A19-img/A19-1.jpg';
import A19_2 from '../media/images/A19-img/A19-2.jpg';
import A19_3 from '../media/images/A19-img/A19-3.jpg';

import A20_1 from '../media/images/A20-img/A20-1.jpg';
import A20_2 from '../media/images/A20-img/A20-2.jpg';
import A20_3 from '../media/images/A20-img/A20-3.jpg';

import A21_1 from '../media/images/A21-img/A21-1.jpg';
import A21_2 from '../media/images/A21-img/A21-2.jpg';
import A21_3 from '../media/images/A21-img/A21-3.jpg';

import A22_1 from '../media/images/A22-img/A22-1.jpg';
import A22_2 from '../media/images/A22-img/A22-2.jpg';
import A22_3 from '../media/images/A22-img/A22-3.jpg';

import A23_1 from '../media/images/A23-img/A23-1.jpg';
import A23_2 from '../media/images/A23-img/A23-2.jpg';

import A24_1 from '../media/images/A24-img/A24-1.jpg';
import A24_2 from '../media/images/A24-img/A24-2.jpg';
import A24_3 from '../media/images/A24-img/A24-3.jpg';

import A25_1 from '../media/images/A25-img/A25-1.jpg';

import A26_1 from '../media/images/A26-img/A26-1.jpg';
import A26_2 from '../media/images/A26-img/A26-2.jpg';
import A26_3 from '../media/images/A26-img/A26-3.jpg';

import A27_1 from '../media/images/A27-img/A27-1.jpg';
import A27_2 from '../media/images/A27-img/A27-2.jpg';

import A28_1 from '../media/images/A28-img/A28-1.jpg';
import A28_2 from '../media/images/A28-img/A28-2.jpg';

import A29_1 from '../media/images/A29-img/A29-1.jpg';
import A29_2 from '../media/images/A29-img/A29-2.jpg';

import A30_1 from '../media/images/A30-img/A30-1.jpeg';
import A30_2 from '../media/images/A30-img/A30-2.jpeg';
import A30_3 from '../media/images/A30-img/A30-3.jpeg';

import A31_1 from '../media/images/A31-img/A31-1.jpeg';
import A31_2 from '../media/images/A31-img/A31-2.jpeg';

import A32_1 from '../media/images/A32-img/A32-1.jpeg';
import A32_2 from '../media/images/A32-img/A32-2.jpeg';
import A32_3 from '../media/images/A32-img/A32-3.jpeg';

import A33_1 from '../media/images/A33-img/A33-1.jpeg';
import A33_2 from '../media/images/A33-img/A33-2.jpeg';
import A33_3 from '../media/images/A33-img/A33-3.jpeg';

import A34_1 from '../media/images/A34-img/A34-1.jpeg';
import A34_2 from '../media/images/A34-img/A34-2.jpeg';
import A34_3 from '../media/images/A34-img/A34-3.jpeg';

import A35_1 from '../media/images/A35-img/A35-1.jpeg';
import A35_2 from '../media/images/A35-img/A35-2.jpeg';
import A35_3 from '../media/images/A35-img/A35-3.jpeg';
import A35_4 from '../media/images/A35-img/A35-4.jpeg';

import A36_1 from '../media/images/A36-img/A36-1.jpeg';
import A36_2 from '../media/images/A36-img/A36-2.jpeg';
import A36_3 from '../media/images/A36-img/A36-3.jpeg';

import B2_1 from '../media/images/B2-img/B2-1.jpg';
import B2_2 from '../media/images/B2-img/B2-2.jpg';
import B2_3 from '../media/images/B2-img/B2-3.jpg';

import B3_1 from '../media/images/B3-img/B3-1.jpg';
import B3_2 from '../media/images/B3-img/B3-2.jpg';
import B3_3 from '../media/images/B3-img/B3-3.jpg';
import B3_4 from '../media/images/B3-img/B3-4.jpg';

import C1_1 from '../media/images/C1-img/C1-1.jpeg';
import C1_2 from '../media/images/C1-img/C1-2.jpeg';
import C1_3 from '../media/images/C1-img/C1-3.jpeg';
import C1_4 from '../media/images/C1-img/C1-4.jpeg';

import C2_1 from '../media/images/C2-img/C2-1.jpeg';

import C3_1 from '../media/images/C3-img/C3-1.jpg';
import C3_2 from '../media/images/C3-img/C3-2.jpg';
import C3_3 from '../media/images/C3-img/C3-3.jpg';

import C4_1 from '../media/images/C4-img/C4-1.jpg';
import C4_2 from '../media/images/C4-img/C4-2.jpg';
import C4_3 from '../media/images/C4-img/C4-3.jpg';
import C4_4 from '../media/images/C4-img/C4-4.jpg';

import C5_1 from '../media/images/C5-img/C5-1.jpg';

import C6_1 from '../media/images/C6-img/C6-1.jpg';
import C6_2 from '../media/images/C6-img/C6-2.jpg';
import C6_3 from '../media/images/C6-img/C6-3.jpg';
import C6_4 from '../media/images/C6-img/C6-4.jpg';

import C7_1 from '../media/images/C7-img/C7-1.jpg';
import C7_2 from '../media/images/C7-img/C7-2.jpg';
import C7_3 from '../media/images/C7-img/C7-3.jpg';
import C7_4 from '../media/images/C7-img/C7-4.jpg';
import C7_5 from '../media/images/C7-img/C7-5.jpg';

import C8_1 from '../media/images/C8-img/C8-1.jpg';
import C8_2 from '../media/images/C8-img/C8-2.jpg';
import C8_3 from '../media/images/C8-img/C8-3.jpg';
import C8_4 from '../media/images/C8-img/C8-4.jpg';

import C9_1 from '../media/images/C9-img/C9-1.jpg';
import C9_2 from '../media/images/C9-img/C9-2.jpg';
import C9_3 from '../media/images/C9-img/C9-3.jpg';
import C9_4 from '../media/images/C9-img/C9-4.jpg';
import C9_5 from '../media/images/C9-img/C9-5.jpg';
import C9_6 from '../media/images/C9-img/C9-6.jpg';

import C10_1 from '../media/images/C10-img/C10-1.jpg';
import C10_2 from '../media/images/C10-img/C10-2.jpg';
import C10_3 from '../media/images/C10-img/C10-3.jpg';
import C10_4 from '../media/images/C10-img/C10-4.jpg';
import C10_5 from '../media/images/C10-img/C10-5.jpg';
import C10_6 from '../media/images/C10-img/C10-6.jpg';

import C11_1 from '../media/images/C11-img/C11-1.jpg';
import C11_2 from '../media/images/C11-img/C11-2.jpg';
import C11_3 from '../media/images/C11-img/C11-3.jpg';
import C11_4 from '../media/images/C11-img/C11-4.jpg';
import C11_5 from '../media/images/C11-img/C11-5.jpg';
import C11_6 from '../media/images/C11-img/C11-6.jpg';

import C12_1 from '../media/images/C12-img/C12-1.jpg';
import C12_2 from '../media/images/C12-img/C12-2.jpg';
import C12_3 from '../media/images/C12-img/C12-3.jpg';
import C12_4 from '../media/images/C12-img/C12-4.jpg';
import C12_5 from '../media/images/C12-img/C12-5.jpg';
import C12_6 from '../media/images/C12-img/C12-6.jpg';

import C13_1 from '../media/images/C13-img/C13-1.heic';
import C13_2 from '../media/images/C13-img/C13-2.heic';
import C13_3 from '../media/images/C13-img/C13-3.jpg';
import C13_4 from '../media/images/C13-img/C13-4.jpg';
import C13_5 from '../media/images/C13-img/C13-5.jpg';

import C14_1 from '../media/images/C14-img/C14-1.jpg';
import C14_2 from '../media/images/C14-img/C14-2.jpg';
import C14_3 from '../media/images/C14-img/C14-3.jpg';

import C15_1 from '../media/images/C15-img/C15-1.jpg';
import C15_2 from '../media/images/C15-img/C15-2.jpg';
import C15_3 from '../media/images/C15-img/C15-3.jpg';
import C15_4 from '../media/images/C15-img/C15-4.jpg';

import C16_1 from '../media/images/C16-img/C16-1.jpg';
import C16_2 from '../media/images/C16-img/C16-2.jpg';
import C16_3 from '../media/images/C16-img/C16-3.jpg';

import C17_1 from '../media/images/C17-img/C17-1.jpg';
import C17_2 from '../media/images/C17-img/C17-2.jpg';
import C17_3 from '../media/images/C17-img/C17-3.jpg';
import C17_4 from '../media/images/C17-img/C17-4.jpg';

import C18_1 from '../media/images/C18-img/C18-1.jpg';
import C18_2 from '../media/images/C18-img/C18-2.jpg';
import C18_3 from '../media/images/C18-img/C18-3.jpg';
import C18_4 from '../media/images/C18-img/C18-4.jpg';
import C18_5 from '../media/images/C18-img/C18-5.jpg';
import C18_6 from '../media/images/C18-img/C18-6.jpg';
import C18_7 from '../media/images/C18-img/C18-7.jpg';
import C18_8 from '../media/images/C18-img/C18-8.jpg';

import C19_1 from '../media/images/C19-img/C19-1.jpg';
import C19_2 from '../media/images/C19-img/C19-2.jpg';
import C19_3 from '../media/images/C19-img/C19-3.jpg';
import C19_4 from '../media/images/C19-img/C19-4.jpg';
import C19_5 from '../media/images/C19-img/C19-5.jpg';

import C20_1 from '../media/images/C20-img/C20-1.jpg';
import C20_2 from '../media/images/C20-img/C20-2.jpg';
import C20_3 from '../media/images/C20-img/C20-3.jpeg';
import C20_4 from '../media/images/C20-img/C20-4.jpeg';

import C21_1 from '../media/images/C21-img/C21-1.jpg';
import C21_2 from '../media/images/C21-img/C21-2.jpg';
import C21_3 from '../media/images/C21-img/C21-3.jpg';
import C21_4 from '../media/images/C21-img/C21-4.jpg';

import C22_1 from '../media/images/C22-img/C22-1.jpg';
import C22_2 from '../media/images/C22-img/C22-2.jpg';
import C22_3 from '../media/images/C22-img/C22-3.jpg';
import C22_4 from '../media/images/C22-img/C22-4.jpg';
import C22_5 from '../media/images/C22-img/C22-5.jpg';
import C22_6 from '../media/images/C22-img/C22-6.jpg';
import C22_7 from '../media/images/C22-img/C22-7.jpg';

import C23_1 from '../media/images/C23-img/C23-1.jpg';
import C23_2 from '../media/images/C23-img/C23-2.jpg';
import C23_3 from '../media/images/C23-img/C23-3.jpg';
import C23_4 from '../media/images/C23-img/C23-4.jpg';

import C24_1 from '../media/images/C24-img/C24-1.jpg';
import C24_2 from '../media/images/C24-img/C24-2.jpeg';

import C25_1 from '../media/images/C25-img/C25-1.jpg';
import C25_2 from '../media/images/C25-img/C25-2.jpg';
import C25_3 from '../media/images/C25-img/C25-3.jpg';
import C25_4 from '../media/images/C25-img/C25-4.jpg';
import C25_5 from '../media/images/C25-img/C25-5.jpg';
import C25_6 from '../media/images/C25-img/C25-6.jpg';
import C25_7 from '../media/images/C25-img/C25-7.jpg';

import C26_1 from '../media/images/C26-img/C26-1.jpg';
import C26_2 from '../media/images/C26-img/C26-2.jpg';
import C26_3 from '../media/images/C26-img/C26-3.jpg';
import C26_4 from '../media/images/C26-img/C26-4.jpg';
import C26_5 from '../media/images/C26-img/C26-5.jpg';
import C26_6 from '../media/images/C26-img/C26-6.jpg';

import C27_1 from '../media/images/C27-img/C27-1.jpg';
import C27_2 from '../media/images/C27-img/C27-2.jpg';
import C27_3 from '../media/images/C27-img/C27-3.jpg';

import C28_1 from '../media/images/C28-img/C28-1.jpg';
import C28_2 from '../media/images/C28-img/C28-2.jpg';
import C28_3 from '../media/images/C28-img/C28-3.jpg';
import C28_4 from '../media/images/C28-img/C28-4.jpg';

import C29_1 from '../media/images/C29-img/C29-1.jpg';
import C29_2 from '../media/images/C29-img/C29-2.jpg';
import C29_3 from '../media/images/C29-img/C29-3.jpg';
import C29_4 from '../media/images/C29-img/C29-4.jpg';

import C30_1 from '../media/images/C30-img/C30-1.jpg';
import C30_2 from '../media/images/C30-img/C30-2.jpg';
import C30_3 from '../media/images/C30-img/C30-3.jpg';
import C30_4 from '../media/images/C30-img/C30-4.jpg';
import C30_5 from '../media/images/C30-img/C30-5.jpg';
import C30_6 from '../media/images/C30-img/C30-6.jpg';

import C31_1 from '../media/images/C31-img/C31-1.jpg';
import C31_2 from '../media/images/C31-img/C31-2.jpg';
import C31_3 from '../media/images/C31-img/C31-3.jpg';
import C31_4 from '../media/images/C31-img/C31-4.jpg';
import C31_5 from '../media/images/C31-img/C31-5.jpg';

import C32_1 from '../media/images/C32-img/C32-1.jpg';
import C32_2 from '../media/images/C32-img/C32-2.jpg';
import C32_3 from '../media/images/C32-img/C32-3.jpg';

import C33_1 from '../media/images/C33-img/C33-1.jpg';
import C33_2 from '../media/images/C33-img/C33-2.jpg';
import C33_3 from '../media/images/C33-img/C33-3.jpg';
import C33_4 from '../media/images/C33-img/C33-4.jpg';

import C34_1 from '../media/images/C34-img/C34-1.jpg';
import C34_2 from '../media/images/C34-img/C34-2.jpg';
import C34_3 from '../media/images/C34-img/C34-3.jpg';

import C35_1 from '../media/images/C35-img/C35-1.jpg';
import C35_2 from '../media/images/C35-img/C35-2.jpg';
import C35_3 from '../media/images/C35-img/C35-3.jpg';

import C36_1 from '../media/images/C36-img/C36-1.jpg';
import C36_2 from '../media/images/C36-img/C36-2.jpg';
import C36_3 from '../media/images/C36-img/C36-3.jpg';
import C36_4 from '../media/images/C36-img/C36-4.jpg';
import C36_5 from '../media/images/C36-img/C36-5.jpg';

import C37_1 from '../media/images/C37-img/C37-1.jpg';
import C37_2 from '../media/images/C37-img/C37-2.jpg';
import C37_3 from '../media/images/C37-img/C37-3.jpg';
import C37_4 from '../media/images/C37-img/C37-4.jpg';

import C38_1 from '../media/images/C38-img/C38-1.jpg';
import C38_2 from '../media/images/C38-img/C38-2.jpg';
import C38_3 from '../media/images/C38-img/C38-3.jpg';

import C39_1 from '../media/images/C39-img/C39-1.jpg';
import C39_2 from '../media/images/C39-img/C39-2.jpg';
import C39_3 from '../media/images/C39-img/C39-3.jpg';
import C39_4 from '../media/images/C39-img/C39-4.jpg';
import C39_5 from '../media/images/C39-img/C39-5.jpg';
import C39_6 from '../media/images/C39-img/C39-6.jpg';
import C39_7 from '../media/images/C39-img/C39-7.jpg';
import C39_8 from '../media/images/C39-img/C39-8.jpg';
import C39_9 from '../media/images/C39-img/C39-9.jpg';

import C40_1 from '../media/images/C40-img/C40-1.jpg';
import C40_2 from '../media/images/C40-img/C40-2.jpg';
import C40_3 from '../media/images/C40-img/C40-3.jpg';

import C41_1 from '../media/images/C41-img/C41-1.jpg';
import C41_2 from '../media/images/C41-img/C41-2.jpg';
import C41_3 from '../media/images/C41-img/C41-3.jpg';
import C41_4 from '../media/images/C41-img/C41-4.jpg';

import C42_1 from '../media/images/C42-img/C42-1.jpg';
import C42_2 from '../media/images/C42-img/C42-2.jpg';
import C42_3 from '../media/images/C42-img/C42-3.jpg';
import C42_4 from '../media/images/C42-img/C42-4.jpg';

import C43_1 from '../media/images/C43-img/C43-1.jpg';
import C43_2 from '../media/images/C43-img/C43-2.jpg';
import C43_3 from '../media/images/C43-img/C43-3.jpg';
import C43_4 from '../media/images/C43-img/C43-4.jpg';

import C44_1 from '../media/images/C44-img/C44-1.jpg';
import C44_2 from '../media/images/C44-img/C44-2.jpg';
import C44_3 from '../media/images/C44-img/C44-3.jpg';
import C44_4 from '../media/images/C44-img/C44-4.jpg';

import C45_1 from '../media/images/C45-img/C45-1.jpg';
import C45_2 from '../media/images/C45-img/C45-2.jpg';
import C45_3 from '../media/images/C45-img/C45-3.jpg';

import C46_1 from '../media/images/C46-img/C46-1.jpg';
import C46_2 from '../media/images/C46-img/C46-2.jpg';
import C46_3 from '../media/images/C46-img/C46-3.jpg';

import C47_1 from '../media/images/C47-img/C47-1.jpg';
import C47_2 from '../media/images/C47-img/C47-2.jpg';
import C47_3 from '../media/images/C47-img/C47-3.jpg';
import C47_4 from '../media/images/C47-img/C47-4.jpg';

import C48_1 from '../media/images/C48-img/C48-1.jpg';
import C48_2 from '../media/images/C48-img/C48-2.jpg';
import C48_3 from '../media/images/C48-img/C48-3.jpg';

import C49_1 from '../media/images/C49-img/C49-1.jpeg';
import C49_2 from '../media/images/C49-img/C49-2.jpeg';
import C49_3 from '../media/images/C49-img/C49-3.jpeg';
import C49_4 from '../media/images/C49-img/C49-4.jpeg';

import C50_1 from '../media/images/C50-img/C50-1.jpeg';

import C51_1 from '../media/images/C51-img/C51-1.jpeg';
import C51_2 from '../media/images/C51-img/C51-2.jpeg';
import C51_3 from '../media/images/C51-img/C51-3.jpeg';

import C52_1 from '../media/images/C52-img/C52-1.jpeg';
import C52_2 from '../media/images/C52-img/C52-2.jpeg';
import C52_3 from '../media/images/C52-img/C52-3.jpeg';

import C53_1 from '../media/images/C53-img/C53-1.jpeg';
import C53_2 from '../media/images/C53-img/C53-2.jpeg';
import C53_3 from '../media/images/C53-img/C53-3.jpeg';
import C53_4 from '../media/images/C53-img/C53-4.jpeg';

import C54_1 from '../media/images/C54-img/C54-1.jpeg';
import C54_2 from '../media/images/C54-img/C54-2.jpeg';

import F1_1 from '../media/images/F1-img/F1-1.jpeg';
import F1_2 from '../media/images/F1-img/F1-2.jpeg';
import F1_3 from '../media/images/F1-img/F1-3.jpeg';
import F1_4 from '../media/images/F1-img/F1-4.jpeg';
import F1_5 from '../media/images/F1-img/F1-5.jpeg';
import F1_6 from '../media/images/F1-img/F1-6.jpeg';
import F1_7 from '../media/images/F1-img/F1-7.jpeg';

import G1_1 from '../media/images/G1-img/G1-1.jpg';
import G1_2 from '../media/images/G1-img/G1-2.jpg';

import G2_1 from '../media/images/G2-img/G2-1.jpg';
import G2_2 from '../media/images/G2-img/G2-2.jpg';
import G2_3 from '../media/images/G2-img/G2-3.jpg';
import G2_4 from '../media/images/G2-img/G2-4.jpg';

import G3_1 from '../media/images/G3-img/G3-1.jpg';
import G3_2 from '../media/images/G3-img/G3-2.jpg';
import G3_3 from '../media/images/G3-img/G3-3.jpg';
import G3_4 from '../media/images/G3-img/G3-4.jpg';
import G3_5 from '../media/images/G3-img/G3-5.jpg';

import G4_1 from '../media/images/G4-img/G4-1.jpg';
import G4_2 from '../media/images/G4-img/G4-2.jpg';
import G4_3 from '../media/images/G4-img/G4-3.jpg';
import G4_4 from '../media/images/G4-img/G4-4.jpg';

import G5_1 from '../media/images/G5-img/G5-1.png';
import G5_2 from '../media/images/G5-img/G5-2.png';
import G5_3 from '../media/images/G5-img/G5-3.png';
import G5_4 from '../media/images/G5-img/G5-4.png';
import G5_5 from '../media/images/G5-img/G5-5.png';

import G6_1 from '../media/images/G6-img/G6-1.jpg';
import G6_2 from '../media/images/G6-img/G6-2.jpg';
import G6_3 from '../media/images/G6-img/G6-3.jpg';
import G6_4 from '../media/images/G6-img/G6-4.jpg';

import G7_1 from '../media/images/G7-img/G7-1.jpg';
import G7_2 from '../media/images/G7-img/G7-2.jpg';
import G7_3 from '../media/images/G7-img/G7-3.jpg';

import I1_1 from '../media/images/I1-img/I1-1.jpg';
import I1_2 from '../media/images/I1-img/I1-2.jpg';
import I1_3 from '../media/images/I1-img/I1-3.jpg';
import I1_4 from '../media/images/I1-img/I1-4.jpg';
import I1_5 from '../media/images/I1-img/I1-5.jpg';

import J1_1 from '../media/images/J1-img/J1-1.jpg';
import J1_2 from '../media/images/J1-img/J1-2.jpg';
import J1_3 from '../media/images/J1-img/J1-3.jpg';
import J1_4 from '../media/images/J1-img/J1-4.jpg';
import J1_5 from '../media/images/J1-img/J1-5.jpg';
import J1_6 from '../media/images/J1-img/J1-6.jpg';
import J1_7 from '../media/images/J1-img/J1-7.jpg';

import K1_1 from '../media/images/K1-img/K1-1.jpg';
import K1_2 from '../media/images/K1-img/K1-2.jpg';
import K1_3 from '../media/images/K1-img/K1-3.jpg';
import K1_4 from '../media/images/K1-img/K1-4.jpg';

import K2_1 from '../media/images/K2-img/K2-1.jpg';
import K2_2 from '../media/images/K2-img/K2-2.jpg';

export const OFFICE_CORDS = {
	lat: 33.310721,
	lng: 44.32696,
};

export const MAP_LOCATION_DATA = [
	{
		title: 'أرض سكنية ملك صرف , ركن', // اسم العرض
		address: 'الموصل /الحمدانية/برطلة/مقابل بدالة برطلة خلف الشارع الرئيسي', // العنوان
		description: null, // الوصف 
		cords: { // الاحداثيات
			lat: 36.356396, // خط الطول
			lng: 43.379560, // خط العرض
		},
		price: 287000000, // السعر
		rooms: null, // الغرف
		bathrooms: null, // الحمامات
		offerType: 'BUY', // نوع العرض (BUY, RENT)
		propertyType: 'SKN', // نوع الملك
		displayPropType: 'أرض سكني', // نوع الملك الظاهر للمستخدم
		contractType: 'ملك صرف', // نوع السند
		date: '7/2/2024', // التاريخ
		space: 190, // المساحة
		location: 'https://maps.app.goo.gl/EsBAi9hc5auX9Jhw7?g_st=it', // الموقع
		dimensions: '10.5م x 11.5م', // الابعاد
		images: [A1_1, A1_2, A1_3], // الصور
		id: 0,
		dataId: 'A1', // الاي دي مال عرض
	},
	{
		title: 'أرض سكنية في الجانب الأيمن من مدينة الموصل . 150 م إضافي يمكن عمل حديقة فيها ',
		address: 'الموصل / الجانب الأيمن /حي الصحة/قرب من بانزيخانة المازن/خلف مطعم الغانم',
		description: null,
		cords: {
			lat: 36.348804,
			lng: 43.098528,
		},
		price: 433000000,
		rooms: 'لايوجد',
		bathrooms: 'لايوجد',
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'أرض سكنية ',
		contractType: 'ملك صرف',
		date: '7/2/2024',
		space: 432.5,
		location: 'https://maps.app.goo.gl/kEVoUTph67r5rGhQA?g_st=iw',
		dimensions: 'القطعة غير منتظمة الواجهة 23م والعمق 18.5م',
		images: [A2_1, A2_2, A2_3],
		id: 1,
		dataId: 'A2',
	},
	{
		title: 'أرض  زراعية صالحة للبناء في منطقة مزارع الحدباء',
		address: 'الموصل/الايسر/مزارع الحدباء',
		description: null,
		cords: {
			lat: 36.411489,
			lng: 43.151438,
		},
		price: 160000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'أرض زراعية',
		contractType: 'مملوكة للدولة',
		date: '8/2/2024',
		space: 400000000,
		location: 'https://maps.app.goo.gl/a3AhnFUrRCYo4NEr6?g_st=iwb',
		dimensions: '16مx25م',
		images: [A3_1, A3_2, A3_3],
		id: 2,
		dataId: 'A3',
	},
	{
		title: 'أرض زراعية صالحة للبناء. فيها وصل بلدية',
		address: 'الموصل/الكندي الثانية/قرب مولة الجود',
		description: null,
		cords: {
			lat: 36.415639,
			lng: 43.149402,
		},
		price: 45000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'أرض زراعية',
		contractType: 'مملوكة للدولة',
		date: '10/2/2024',
		space: 150,
		location: 'https://maps.app.goo.gl/gGxJMiqL6KQ1yytt8?g_st=iw',
		dimensions: '7.5م x 20م',
		images: [A4_1, A4_2, A4_3],
		id: 3,
		dataId: 'A4',
	},
	{
		title: 'أرض سكنية ملك صرف صالحة للبناء',
		address: 'الموصل/حي السماح الثانية/مقابل مشروع الماء الثاني',
		description: null,
		cords: {
			lat: 36.362528,
			lng: 43.234278,
		},
		price: 165000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'أرض سكنية',
		contractType: 'ملك صرف',
		date: '11/2/2024',
		space: 200,
		location: 'https://maps.app.goo.gl/E6hs8xG9TMYCWeyH9',
		dimensions: '10م x 20م',
		images: [A5_1, A5_2],
		id: 4,
		dataId: 'A5',
	},
	{
		title: 'أرض سكنية ملك صرف صالحة للبناء',
		address: 'الموصل/حي الشيماء/خلف مدرسة الثامنة الابتدائية ',
		description: null,
		cords: {
			lat: 36.304133,
			lng: 43.217355,
		},
		price: 150000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'أرض سكنية',
		contractType: 'ملك صرف',
		date: '18/2/2024',
		space: 213,
		location: 'https://maps.app.goo.gl/yWLyLLHmVkjYQDca6',
		dimensions: '13م x 16.38م',
		images: [A6_1, A6_2, A6_3],
		id: 5,
		dataId: 'A6',
	},
	{
		title: 'قطعة أرض تجارية',
		address: 'الموصل/حي الاندلس/قرب مدخل الاندلس ',
		description: null,
		cords: {
			lat: 36.380611,
			lng: 43.133556,
		},
		price: 2070000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'TGY',
		displayPropType: 'أرض تجاري',
		contractType: 'ملك صرف',
		date: '18/2/2024',
		space: 828,
		location: 'https://maps.app.goo.gl/GLob5KdHhBeAv6WM7',
		dimensions: '30.60م x 26.20م',
		images: [A7_1, A7_2, A7_3],
		id: 6,
		dataId: 'A7',
	},
	{
		title: 'أرض زراعي صالح للبناء يمتلك وصل بلدية',
		address: 'الموصل/حي الفلاح الأولى /شارع الحلبوسي/جامع محمد فائق',
		description: null,
		cords: {
			lat: 36.392222,
			lng: 43.177722,
		},
		price: 80000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'أرض سكنية',
		contractType: 'أرض زراعية',
		date: '19/2/2024',
		space: 100,
		location: 'https://maps.app.goo.gl/RDr5GvzaeB1jFcsW8',
		dimensions: '5م x 20م',
		images: [A8_1, A8_2, A8_3],
		id: 7,
		dataId: 'A8',
	},
	{
		title: 'أرض سكني ملك صرف',
		address: 'الموصل/حي النور/سايدين مسجد عائشة /مقابل عجمان للعقار',
		description: null,
		cords: {
			lat: 36.363583,
			lng: 43.188972,
		},
		price: 388000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'أرض سكنية',
		contractType: 'ملك صرف',
		date: '21/2/2024',
		space: 288,
		location: 'https://maps.app.goo.gl/Y4dHDiuWSdEbYsYL7',
		dimensions: '9م x  32م',
		images: [A9_1, A9_2, A9_3],
		id: 8,
		dataId: 'A9',
	},
	{
		title: 'أرض زراعي يحتوي على وصل بلدية',
		address: 'الموصل/حي الملايين الثانية/مجاور مجمع الشام',
		description: null,
		cords: {
			lat: 36.435472,
			lng: 43.092389,
		},
		price: 220000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'أرض سكنية',
		contractType: 'أرض زراعي',
		date: '18/2/2024',
		space: 192,
		location: 'https://maps.app.goo.gl/Kasfi4UTX7FydpzNA',
		dimensions: '12م x 16م',
		images: [A10_1, A10_2, A10_3],
		id: 9,
		dataId: 'A10',
	},
	{
		title: 'أرض سكني ملك صرف',
		address: 'الموصل/سايدين العربي مجاور معمل الالبان',
		description: null,
		cords: {
			lat: 36.406222,
			lng: 43.110333,
		},
		price: 252000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'أرض سكنية',
		contractType: 'ملك صرف ',
		date: '21/2/2024',
		space: 240,
		location: 'https://maps.app.goo.gl/wag1B7ydCRw2DbrVA',
		dimensions: '12م x 20م',
		images: [A11_1, A11_2, A11_3],
		id: 10,
		dataId: 'A11',
	},
	{
		title: 'أرض سكني ملك صرف',
		address: 'الموصل/حي النجار /خلف افران بغداد الثانية',
		description: null,
		cords: {
			lat: 36.357917,
			lng: 43.104528,
		},
		price: 160000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'أرض سكنية',
		contractType: 'ملك صرف ',
		date: '26/2/2024',
		space: 200,
		location: 'https://maps.app.goo.gl/N9xWV3FyZt3qGhht8',
		dimensions: '10م x 20م',
		images: [A12_1, A12_2, A12_3],
		id: 11,
		dataId: 'A12',
	},
	{
		title: 'أرض التجارية في سوق النبي',
		address: 'الموصل/النبي يونس /شارع المركز الشرطة في الفرع القريب',
		description: null,
		cords: {
			lat: 36.351500,
			lng: 43.159083,
		},
		price: 588000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: ['SKN', 'TGY'],
		displayPropType: 'أرض تجارية',
		contractType: 'ملك صرف ',
		date: '28/2/2024',
		space: 470,
		location: 'https://maps.app.goo.gl/kke9aNwPKCQKZSCJ7',
		dimensions: '24م x 19م',
		images: [A13_1, A13_2, A13_3],
		id: 12,
		dataId: 'A13',
	},
	{
		title: 'أرض سكني ',
		address: 'الموصل/الكندي الثانية/قرب مولدة الجود ',
		description: null,
		cords: {
			lat: 36.418639,
			lng: 43.148139,
		},
		price: 40000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'أرض سكني',
		contractType: 'أرض زراعي',
		date: '26/2/2024',
		space: 100,
		location: 'https://maps.app.goo.gl/qjGCWL7be497BmV17',
		dimensions: '5م X 20م',
		images: [A14_1, A14_2, A14_3],
		id: 13,
		dataId: 'A14',
	},
	{
		title: 'أرض سكني ملك صرف',
		address: 'الموصل/سايدين السدة/مقابل الثروة الحيوانية',
		description: null,
		cords: {
			lat: 36.406722,
			lng: 43.109111,
		},
		price: 310000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'أرض سكني',
		contractType: 'ملك صرف',
		date: '27/2/2024',
		space: 258,
		location: 'https://maps.app.goo.gl/9rqMRM6guGJN7Uwg6',
		dimensions: '12.33م X 20م',
		images: [A15_1, A15_2, A15_3],
		id: 14,
		dataId: 'A15',
	},
	{
		title: 'أرض سكني ملك صرف',
		address: 'الموصل/حي التعليم الثانية/طريق تلكيف/فرع الخامس/بجانب الجامع الجديد',
		description: null,
		cords: {
			lat: 36.443167,
			lng: 43.112944,
		},
		price: 150000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'أرض سكني',
		contractType: 'ملك صرف',
		date: '2/3/2024',
		space: 298,
		location: 'https://maps.app.goo.gl/N8nkEyxrqxVyTm3R8',
		dimensions: '20م X 15م',
		images: [A16_1, A16_2, A16_3],
		id: 15,
		dataId: 'A16',
	},
	{
		title: 'أرض سكني ملك صرف',
		address: 'الموصل/حي التاميم/مقابل محطة التاميم',
		description: null,
		cords: {
			lat: 36.350722,
			lng: 43.191500,
		},
		price: 215000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'أرض سكني',
		contractType: 'ملك صرف',
		date: '2/3/2024',
		space: 238,
		location: 'https://maps.app.goo.gl/6vuirvRY5yCECNcY9',
		dimensions: '11م X 21م',
		images: [A17_1, A17_2, A17_3],
		id: 16,
		dataId: 'A17',
	},
	{
		title: 'أرض سكني ملك صرف',
		address: 'الموصل/ حي الصحة الثانية/فرع المول والملعب',
		description: null,
		cords: {
			lat: 36.306583,
			lng: 43.212528,
		},
		price: 175000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'أرض سكني',
		contractType: 'ملك صرف',
		date: '22/2/2024',
		space: 250,
		location: 'https://maps.app.goo.gl/a6JBZRVD9T1Am7nE8',
		dimensions: '12.5م x 20م',
		images: [A18_1, A18_2, A18_3],
		id: 17,
		dataId: 'A18',
	},
	{
		title: 'أرض سكني ملك صرف',
		address: 'الموصل/ناحية برطلة/حي النسيم الثانية/خلف مدرسة ثانوية برطلة للبنات',
		description: null,
		cords: {
			lat: 36.354889,
			lng: 43.394306,
		},
		price: 120000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'أرض سكني',
		contractType: 'ملك صرف',
		date: '5/3/2024',
		space: 250,
		location: 'https://maps.app.goo.gl/r8Aitj1nddqGnVwq7',
		dimensions: '12.5م X 20م',
		images: [A19_1, A19_2, A19_3],
		id: 18,
		dataId: 'A19',
	},
	{
		title: 'أرض سكني ملك صرف',
		address: 'الموصل/حي الميثاق/مقابل خدمات الشرق الاوسط',
		description: null,
		cords: {
			lat: 36.335167,
			lng: 43.190333,
		},
		price: 250000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'أرض سكني',
		contractType: 'ملك صرف',
		date: '9/3/2024',
		space: 200,
		location: 'https://maps.app.goo.gl/GTQj37xCsU5D8xsu8',
		dimensions: '10م X 20م',
		images: [A20_1, A20_2, A20_3],
		id: 19,
		dataId: 'A20',
	},
	{
		title: 'أرض سكني',
		address: 'حي الملايين الثانية قرب جامع اهل القرأن مقابل مخارن السيراميك',
		description: null,
		cords: {
			lat: 36.428417,
			lng: 43.098361,
		},
		price: 296000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'أرض سكني',
		contractType: 'زراعي بستان 25',
		date: '9/3/2024',
		space: 740,
		location: 'https://maps.app.goo.gl/2JhkdCATmgxbhEms5',
		dimensions: '37مX  20 م',
		images: [A21_1, A21_2, A21_3],
		id: 20,
		dataId: 'A21',
	},
	{
		title: 'أرض سكني',
		address: 'موصل حي الملايين مقابل معمل الاخشاب ',
		description: null,
		cords: {
			lat: 36.414806,
			lng: 43.096944,
		},
		price: 340000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'أرض سكني',
		contractType: 'بستان تسقى مطرا',
		date: '9/3/2024',
		space: 544,
		location: 'https://maps.app.goo.gl/JEBJhV4mhtj9ond59',
		dimensions: '32م X  17 م',
		images: [A22_1, A22_2, A22_3],
		id: 21,
		dataId: 'A22',
	},
	{
		title: 'ارض سكنية ملك صرف',
		address: 'موصل حي الحدباء',
		description: null,
		cords: {
			lat: 36.405222,
			lng: 43.150833,
		},
		price: 210000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'أرض سكني',
		contractType: 'ملك صرف',
		date: '9/3/2024',
		space: 200,
		location: 'https://maps.app.goo.gl/jTj59uTRBcKwg5hM7',
		dimensions: '10م X 20م',
		images: [A23_1, A23_2],
		id: 22,
		dataId: 'A23',
	},
	{
		title: 'ارض سكنية ملك صرف',
		address: 'موصل حي الزهور طريق الخوصر',
		description: null,
		cords: {
			lat: 36.371306,
			lng: 43.179028,
		},
		price: 500000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'أرض سكني',
		contractType: 'ملك صرف',
		date: '11/3/2024',
		space: 250,
		location: 'https://maps.app.goo.gl/h7NxEra5GFfcizQMA',
		dimensions: '26م  X  19.11م',
		images: [A24_1, A24_2, A24_3],
		id: 23,
		dataId: 'A24',
	},
	{
		title: 'ارض سكنية ملك صرف',
		address: 'موصل حي الزهور قرب جرزات الكرم',
		description: null,
		cords: {
			lat: 36.371778,
			lng: 43.179111,
		},
		price: 958000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'أرض سكني',
		contractType: 'ملك صرف',
		date: '13/3/2024',
		space: 479,
		location: 'https://maps.app.goo.gl/EFzUsoWHchvLZrnZ6',
		dimensions: '15م   X31م',
		images: [A25_1],
		id: 24,
		dataId: 'A25',
	},
	{
		title: 'ارض سكنية ملك صرف',
		address: 'موصل حب الرفاعي قرب جامع يحيى الطالب',
		description: null,
		cords: {
			lat: 36.355111,
			lng: 43.099111,
		},
		price: 407000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'أرض سكني',
		contractType: 'ملك صرف طابو الايمن',
		date: '16/3/2024',
		space: 236,
		location: 'https://maps.app.goo.gl/jyAkEcFxFcd8Z9Zi9',
		dimensions: '15م X 16م',
		images: [A26_1, A26_2, A26_3],
		id: 25,
		dataId: 'A26',
	},
	{
		title: 'ارض سكنية',
		address: 'موصل مزارع الحدباء الثانية قرب جامع سدرة المنتهى',
		description: null,
		cords: {
			lat: 36.411556,
			lng: 43.152389,
		},
		price: 66000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'أرض سكني',
		contractType: 'وصل بلدية',
		date: '12/3/2024',
		space: 164,
		location: 'https://maps.app.goo.gl/uwpHS9yhtrsD34uj8',
		dimensions: '7.80م  X21.5م',
		images: [A27_1, A27_2],
		id: 26,
		dataId: 'A27',
	},
	{
		title: 'ارض سكنية',
		address: 'موصل حي الملايين الثانية قرب من الجامع الجديد',
		description: null,
		cords: {
			lat: 36.437722,
			lng: 43.092889,
		},
		price: 58000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'أرض سكني',
		contractType: 'زراعي وصل بلدية',
		date: '16/3/2024',
		space: 233,
		location: 'https://maps.app.goo.gl/CZBi3PhL2ZcjoB5W9',
		dimensions: '10م X 23 م',
		images: [A28_1, A28_2],
		id: 27,
		dataId: 'A28',
	},
	{
		title: 'ارض سكنية',
		address: 'موصل حي الملايين الثانية قرب من الجامع الجديد',
		description: null,
		cords: {
			lat: 36.437472,
			lng: 43.093833,
		},
		price: 45000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'أرض سكني',
		contractType: 'زراعي وصل بلدية',
		date: '16/3/2024',
		space: 200,
		location: 'https://maps.app.goo.gl/CZBi3PhL2ZcjoB5W9',
		dimensions: '10م X 20م',
		images: [A29_1, A29_2],
		id: 28,
		dataId: 'A29',
	},
	{
		title: 'أرض زراعية مساحة 7.5 دونم للبيع فقط 4  دونم . وعليها طريق',
		address: 'الموصل/قرب معمل الادوية/طريق دهوك القديم /بعد القوسيات',
		description: null,
		cords: {
			lat: 36.4731614,
			lng: 43.0745909,
		},
		price: 600000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'ZRY',
		displayPropType: 'أرض زراعية',
		contractType: 'مملوكة للدولة',
		date: '13/2/2024',
		space: '7.5 دونم',
		location: 'https://maps.app.goo.gl/Hn4HnD6aPP6BBpkQ9',
		dimensions: null,
		images: [B2_1, B2_2, B2_3],
		id: 29,
		dataId: 'B2',
	},
	{
		title: 'أرض زراعية ملك صرف / بستان يسقى من بئر',
		address: 'الموصل/حي الملايين الأولى/مقابل مخازن السيراميك',
		description: null,
		cords: {
			lat: 36.428417,
			lng: 43.098361,
		},
		price: 296000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'ZRY',
		displayPropType: 'أرض زراعية/ بستان ملك صرف',
		contractType: ' أرض زراعية مملوكة للدولة ',
		date: '26/2/2024',
		space: '37م x 20م',
		location: 'https://maps.app.goo.gl/gvBNf5A9pCX8K6wu9',
		dimensions: '37م x 20م',
		images: [B3_1, B3_2, B3_3, B3_4],
		id: 30,
		dataId: 'B3',
	},
	{
		title: 'نص قطعة مشيدة عليها دار ', 
		address: 'الموصل/الحمدانية/برطلة/حي الربيع',
		description: null,
		cords: {
			lat: 36.359750,
			lng: 43.377778,
		},
		price: 85000000,
		rooms: '2 + 1 أستقبال',
		bathrooms: 1,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني',
		contractType: 'ملك صرف',
		date: '8/2/2024',
		space: 125,
		location: 'https://maps.app.goo.gl/NuJJPDuBoa5mj7x28',
		dimensions: '5م x  25م',
		images: [C1_1, C1_2, C1_3, C1_4],
		id: 31,
		dataId: 'C1',
	},
	{
		title: 'هيكلين غير كاملين',
		address: 'الموصل/الحمدانية/برطلة/مجمع حياة/ثاني فرع رابع بيت',
		description: null,
		cords: {
			lat: 36.342611,
			lng: 43.373444,
		},
		price: 70000000,
		rooms: '2+1 أستقبال في كل بيت',
		bathrooms: '2 في كل بيت',
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'أرض زراعي',
		contractType: 'مملوكة للدولة',
		date: '9/2/2024',
		space: 288,
		location: 'https://maps.app.goo.gl/pbr86WcKkKsehbd46',
		dimensions: '13م x  22م',
		images: [C2_1],
		id: 32,
		dataId: 'C2',
	},
	{
		title: 'أرض زراعي في كارت بلدية/ بيت شرقي',
		address: 'الموصل/حي صدام / سنتر حسين قريب من محطة الكهرباء',
		description: null,
		cords: {
			lat: 36.377278,
			lng: 43.210028,
		},
		price: 70000000,
		rooms: '3 + 1 أستقبال',
		bathrooms: 1,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'أرض زراعي',
		contractType: 'مملوكة للدولة',
		date: '10/2/2024',
		space: 200,
		location: 'https://maps.app.goo.gl/1u5Ax7Et2GywmBSz7',
		dimensions: '10م x  20م',
		images: [C3_1,C3_2, C3_3],
		id: 33,
		dataId: 'C3',
	},
	{
		title: 'مباع',
		address: 'الموصل/الأيمن/المكاوي/قرب جامع جمشيت',
		description: null,
		cords: {
			lat: 36.344694,
			lng: 43.127056,
		},
		price: 77000000,
		rooms: '3 + 1 أستقبال + سرداب',
		bathrooms: 1,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني',
		contractType: 'ملك صرف',
		date: '10/2/2024',
		space: 164,
		location: 'https://maps.app.goo.gl/7khf2u5uJu34aocM8',
		dimensions: '12م x  13م',
		images: [C4_1,C4_2, C4_3, C4_4],
		id: 34,
		dataId: 'C4',
	},
	{
		title: 'يحتوي العقار على منزلين الأول 2+1 , الثاني 4+2 ويحتوي على 3 محلات تجارية .',
		address: 'الموصل/حي النور/مقابل مجمع المهند',
		description: null,
		cords: {
			lat: 36.366944,
			lng: 43.189111,
		},
		price: 2112000000,
		rooms: 'البيت الاول2+1 , البيت الثاني 4+2',
		bathrooms: 'البيت الأول 2 , اما البيت الثاني 3',
		offerType: 'BUY',
		propertyType: ['TGY', 'BYT'],
		displayPropType: 'دار تجاري',
		contractType: 'ملك صرف',
		date: '11/2/2024',
		space: 384,
		location: 'https://maps.app.goo.gl/89ZtnPLBVrzqZfcw8',
		dimensions: '12م x  33م',
		images: [C5_1],
		id: 35,
		dataId: 'C5',
	},
	{
		title: 'دار تجاري يحتوي على 3 محلات خارجة من الدار',
		address: 'الموصل/حي السكر/مقابل جامع الرحمن/مقابل مرطبات خالد',
		description: null,
		cords: {
			lat: 36.391500,
			lng: 43.167972,
		},
		price: 1250000000,
		rooms: '2+5',
		bathrooms: 1,
		offerType: 'BUY',
		propertyType: ['TGY', 'BYT'],
		displayPropType: 'دار تجاري',
		contractType: 'ملك صرف',
		date: '12/2/2024',
		space: 357,
		location: 'https://maps.app.goo.gl/2qWgvomhSJ51FeDL8',
		dimensions: '17م x 22م',
		images: [C6_1, C6_2, C6_3, C6_4],
		id: 36,
		dataId: 'C6',
	},
	{
		title: 'بيتين بمساحة 413 م عدد الغرف 7 وعدد الحمامات 2 (7 ورثة)',
		address: 'الموصل/الأيمن/المكاوي ملاصق للتكية',
		description: null,
		cords: {
			lat: 36.344000,
			lng: 43.125639,
		},
		price: 207000000,
		rooms: '7',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني',
		contractType: 'ملك صرف',
		date: '15/2/2024',
		space: 413,
		location: 'https://maps.app.goo.gl/8Wijqgg7DU2CpbtUA',
		dimensions: '20م x 18م',
		images: [C7_1, C7_2, C7_3, C7_4, C7_5],
		id: 37,
		dataId: 'C7',
	},
	{
		title: 'دار ركن خارج منه محل واحد مؤجر ',
		address: 'الموصل/الايسر/حي النور /مقابل سنتر الكرم/قرب مسجد عائشة',
		description: null,
		cords: {
			lat: 36.362833,
			lng: 43.190333,
		},
		price: 750000000,
		rooms: '4',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني',
		contractType: 'ملك صرف',
		date: '15/2/2024',
		space: 300,
		location: 'https://maps.app.goo.gl/9mNoe1wvhYVwwD8FA',
		dimensions: '15م x 20م',
		images: [C8_1, C8_2, C8_3, C8_4],
		id: 38,
		dataId: 'C8',
	},
	{
		title: 'بيت طابقين خمسة غرف واستقبال وصالتين / أرض زراعي بستان ملك صرف / بناء حديث',
		address: 'الموصل/حي الملايين/خلف فلل العناز',
		description: null,
		cords: {
			lat: 36.419750,
			lng: 43.091306,
		},
		price: 140000000,
		rooms: '5',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني',
		contractType: 'أرض زراعي بستان',
		date: '17/2/2024',
		space: 140,
		location: 'https://maps.app.goo.gl/8cVASxKVmUFXqD8h8',
		dimensions: '7.5 م x 18.6 م',
		images: [C9_1, C9_2, C9_3, C9_4, C9_5, C9_6],
		id: 39,
		dataId: 'C9',
	},
	{
		title: 'بيت طابقين خمسة غرف واستقبال وصالتين / أرض زراعي بستان ملك صرف / بناء حديث',
		address: 'الموصل/حي الملايين/خلف فلل العناز',
		description: null,
		cords: {
			lat: 36.419750,
			lng: 43.091306,
		},
		price: 140000000,
		rooms: '5',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني',
		contractType: 'أرض زراعي بستان',
		date: '17/2/2024',
		space: 140,
		location: 'https://maps.app.goo.gl/8cVASxKVmUFXqD8h8',
		dimensions: '7.5 م x 18.6 م',
		images: [C10_1, C10_2, C10_3, C10_4, C10_5, C10_6],
		id: 40,
		dataId: 'C10',
	},
	{
		title: 'بيت طابقين خمسة غرف واستقبال وصالتين / أرض زراعي بستان ملك صرف / بناء حديث',
		address: 'الموصل/حي الملايين/خلف فلل العناز',
		description: null,
		cords: {
			lat: 36.419750,
			lng: 43.091306,
		},
		price: 140000000,
		rooms: '5',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني',
		contractType: 'أرض زراعي بستان',
		date: '17/2/2024',
		space: 140,
		location: 'https://maps.app.goo.gl/8cVASxKVmUFXqD8h8',
		dimensions: '7.5 م x 18.6 م',
		images: [C11_1, C11_2, C11_3, C11_4, C11_5, C11_6],
		id: 41,
		dataId: 'C11',
	},
	{
		title: 'بيت طابقين خمسة غرف واستقبال وصالتين / أرض زراعي بستان ملك صرف / بناء حديث',
		address: 'الموصل/حي الملايين/خلف فلل العناز',
		description: null,
		cords: {
			lat: 36.419750,
			lng: 43.091306,
		},
		price: 140000000,
		rooms: '5',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني',
		contractType: 'أرض زراعي بستان',
		date: '17/2/2024',
		space: 140,
		location: 'https://maps.app.goo.gl/8cVASxKVmUFXqD8h8',
		dimensions: '7.5 م x 18.6 م',
		images: [C12_1, C12_2, C12_3, C12_4, C12_5, C12_6],
		id: 42,
		dataId: 'C12',
	},
	{
		title: 'بيت غير كامل / وصل بلدية',
		address: 'الموصل/حي الزيونة/مقابل المرور',
		description: null,
		cords: {
			lat: 36.403278,
			lng: 43.201806,
		},
		price: 125000000,
		rooms: '4',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني',
		contractType: 'أرض زراعي ',
		date: '17/2/2024',
		space: 143,
		location: 'https://maps.app.goo.gl/UZ1JTc5kk5Mfy6NE7',
		dimensions: '10م x 14.3م',
		images: [C13_1, C13_2, C13_3, C13_4, C13_5],
		id: 43,
		dataId: 'C13',
	},
	{
		title: 'ملك صرف / نصف مشيد على نصف قطعة',
		address: 'الموصل/حي الميثاق/جامع صالح النور /قرب أسواق عدنان',
		description: null,
		cords: {
			lat: 36.334750,
			lng: 43.193861,
		},
		price: 120000000,
		rooms: '3',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني',
		contractType: 'ملك صرف',
		date: '17/2/2024',
		space: 100,
		location: 'https://maps.app.goo.gl/WaDypveoWnortbJq6',
		dimensions: '10م x  10م',
		images: [C14_1, C14_2, C14_3],
		id: 44,
		dataId: 'C14',
	},
	{
		title: 'ملك صرف / دار+ شقة ملك صرف',
		address: 'الموصل/حي المهندسين/قرب جامع وجيه الصيدلي',
		description: null,
		cords: {
			lat: 36.366361,
			lng: 43.135278,
		},
		price: 800000000,
		rooms: '4',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني',
		contractType: 'ملك صرف',
		date: '18/2/2024',
		space: 322,
		location: 'https://maps.app.goo.gl/tV5PPWJHwygepJGP7',
		dimensions: '16م x 20م',
		images: [C15_1, C15_2, C15_3, C15_4],
		id: 45,
		dataId: 'C15',
	},
	{
		title: 'دار سكني + أرض سكني على نفس القطعة',
		address: 'الموصل/حي الوحدة/سايدين السبعاوي/قرب مالك للتسوق',
		description: null,
		cords: {
			lat: 36.331389,
			lng: 43.177889,
		},
		price: 3000000000,
		rooms: '5',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني + أرض سكنية',
		contractType: 'ملك صرف',
		date: '18/2/2024',
		space: 1200,
		location: 'https://maps.app.goo.gl/UXZTqhaDUfkFhuBD8',
		dimensions: '41م x 29م',
		images: [C16_1, C16_2, C16_3],
		id: 46,
		dataId: 'C16',
	},
	{
		title: 'دار سكني ملك صرف',
		address: 'الموصل/حي الشقق الخضراء/قرب مدرسة الامين',
		description: null,
		cords: {
			lat: 36.363000,
			lng: 43.224583,
		},
		price: 90000000,
		rooms: '3+1',
		bathrooms: 1,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني ',
		contractType: 'ملك صرف',
		date: '18/2/2024',
		space: 125,
		location: 'https://maps.app.goo.gl/uys5p5zeYh5wyMFr5',
		dimensions: 'لانعلم',
		images: [C17_1, C17_2, C17_3, C17_4],
		id: 47,
		dataId: 'C17',
	},
	{
		title: 'دار سكني ملك صرف',
		address: 'الموصل /حي الأطباء/مقابل قاعة بيست فيو',
		description: null,
		cords: {
			lat: 36.332222,
			lng: 43.172500,
		},
		price: 495000000,
		rooms: '4+1',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني ',
		contractType: 'ملك صرف',
		date: '20/2/2024',
		space: 300,
		location: 'https://maps.app.goo.gl/FXKcMDL2jLyHfjvu9',
		dimensions: '15م x 20م',
		images: [C18_1, C18_2, C18_3, C18_4, C18_5, C18_6, C18_7, C18_8],
		id: 48,
		dataId: 'C18',
	},
	{
		title: 'دار سكني ملك صرف',
		address: 'الموصل/حي الوحدة/قرب من جامع عبد الرزاق',
		description: null,
		cords: {
			lat: 36.319889,
			lng: 43.200333,
		},
		price: 317000000,
		rooms: '6+1',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني ',
		contractType: 'ملك صرف',
		date: '20/2/2024',
		space: 333,
		location: 'https://maps.app.goo.gl/JgtggkXXtCuG8yNfA',
		dimensions: '12م x 28م',
		images: [C19_1, C19_2, C19_3, C19_4, C19_5],
		id: 49,
		dataId: 'C19',
	},
	{
		title: 'دار سكني ملك صرف',
		address: 'الموصل/حي الزهور/خلف مطعم سيدة الجميلة',
		description: null,
		cords: {
			lat: 36.372472,
			lng: 43.178750,
		},
		price: 1000000000,
		rooms: '7',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني ',
		contractType: 'ملك صرف',
		date: '21/2/2024',
		space: 600,
		location: 'https://maps.app.goo.gl/MHNLULKj74qjqXSa7',
		dimensions: '20م x 30م',
		images: [C20_1, C20_2, C20_3, C20_4],
		id: 50,
		dataId: 'C20',
	},
	{
		title: 'دار تجاري وركن ',
		address: 'الموصل/حي البريد/مجاور مطعم أبو ليلى',
		description: null,
		cords: {
			lat: 36.383583,
			lng: 43.172028,
		},
		price: 1500000000,
		rooms: '3+2',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'TGY',
		displayPropType: 'دار تجاري',
		contractType: 'ملك صرف',
		date: '22/2/2024',
		space: 405,
		location: 'https://maps.app.goo.gl/t3rxAesKL3cdGjHL7',
		dimensions: '18م x 22.5م',
		images: [C21_1, C21_2, C21_3, C21_4],
		id: 51,
		dataId: 'C21',
	},
	{
		title: 'دار سكني /ملك صرف',
		address: 'الموصل/حي البكر(حي الصحة الأولى)/مقابل سنتر اسطنبول',
		description: null,
		cords: {
			lat: 36.360917,
			lng: 43.198000,
		},
		price: 250000000,
		rooms: '5+2',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني',
		contractType: 'ملك صرف',
		date: '5/3/2024',
		space: 209,
		location: 'https://maps.app.goo.gl/JMWoVEAQc41yDGCC6',
		dimensions: '13م X 16م',
		images: [C22_1, C22_2, C22_3, C22_4, C22_5, C22_6, C22_7],
		id: 52,
		dataId: 'C22',
	},
	{
		title: 'دار تجاري ملك صرف',
		address: 'الموصل / حي النور/ قرب مطعم بيت جدي',
		description: null,
		cords: {
			lat: 36.364000,
			lng: 43.195778,
		},
		price: 1620000000,
		rooms: '4 غرف + محلين + سرداب',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: ['BYT', 'TGY'],
		displayPropType: 'دار تجاري ',
		contractType: 'ملك صرف',
		date: '22/2/2024',
		space: 360,
		location: 'https://maps.app.goo.gl/2uJy7ZwDLuhWQfUr5',
		dimensions: '12.5م X 30م',
		images: [C23_1, C23_2, C23_3, C23_4],
		id: 53,
		dataId: 'C23',
	},
	{
		title: 'دار سكني ملك صرف',
		address: 'الموصل/الأيمن/حي الطيران/مقابل مجلس النواب',
		description: null,
		cords: {
			lat: 36.321500,
			lng: 43.137444,
		},
		price: 325000000,
		rooms: '5',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني',
		contractType: 'ملك صرف',
		date: '24/2/2024',
		space: 409,
		location: 'https://maps.app.goo.gl/kZkoxc7uUoq5qG8C7',
		dimensions: '14.5م x 29م',
		images: [C24_1, C24_2],
		id: 54,
		dataId: 'C24',
	},
	{
		title: 'مباع',
		address: 'الموصل/حي الاربجية/سايدين القادسية/خلف القصر الأبيض للاثاث التركي',
		description: null,
		cords: {
			lat: 36.373194,
			lng: 43.196722,
		},
		price: 260000000,
		rooms: '2',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني',
		contractType: 'ملك صرف',
		date: '26/2/2024',
		space: 150,
		location: 'https://maps.app.goo.gl/bttCCzFcfFMhW3UF9',
		dimensions: '10م x 15م',
		images: [C25_1, C25_2, C25_3, C25_4, C25_5, C25_6, C25_7],
		id: 55,
		dataId: 'C25',
	},
	{
		title: 'دار سكني ملك الصرف',
		address: 'الموصل/الزهور قرب سيدية الجميلة/طريق الرابط بين الزهور والمثنى',
		description: null,
		cords: {
			lat: 36.373194,
			lng: 43.178250,
		},
		price: 3000000000,
		rooms: '4+2',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني',
		contractType: 'ملك صرف',
		date: '27/2/2024',
		space: 610,
		location: 'https://maps.app.goo.gl/fxTTwYqFb4rG6BEu5',
		dimensions: '21م x 29.5م',
		images: [C26_1, C26_2, C26_3, C26_4, C26_5, C26_6],
		id: 56,
		dataId: 'C26',
	},
	{
		title: 'دار سكني ملك الصرف',
		address: 'الموصل/حي الاخاء/فرع الوطنية/أخر فرع عاليسار قبل نهاية حي الاخاء',
		description: null,
		cords: {
			lat: 36.358333,
			lng: 43.204361,
		},
		price: 210000000,
		rooms: '4+1',
		bathrooms: 1,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني',
		contractType: 'ملك صرف',
		date: '28/2/2024',
		space: 200,
		location: 'https://maps.app.goo.gl/qTeFyoR38Fr4kY5Y8',
		dimensions: '10م x 20م',
		images: [C27_1, C27_2, C27_3],
		id: 57,
		dataId: 'C27',
	},
	{
		title: 'دار تجاري ملك صرف',
		address: 'الموصل/حي الاعلام/سايدين حي البكر /مقابل حلويات غسان',
		description: null,
		cords: {
			lat: 36.361194,
			lng: 43.196694,
		},
		price: 1100000000,
		rooms: '3+1',
		bathrooms: 1,
		offerType: 'BUY',
		propertyType: ['BYT', 'TGY'],
		displayPropType: 'دار سكني / دار تجاري',
		contractType: 'ملك صرف',
		date: '28/2/2024',
		space: 239,
		location: 'https://maps.app.goo.gl/SY7xXm3juCtCtcR68',
		dimensions: '10م X 24م',
		images: [C28_1, C28_2, C28_3, C28_4],
		id: 58,
		dataId: 'C28',
	},
	{
		title: 'دار تجاري ملك صرف',
		address: 'الموصل/حي الفلاح الأولى/مقابل جامع بشار قلندار',
		description: null,
		cords: {
			lat: 36.389083,
			lng: 43.181500,
		},
		price: 1500000000,
		rooms: '5+1',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: ['BYT', 'TGY'],
		displayPropType: 'دار سكني / دار تجاري',
		contractType: 'ملك صرف',
		date: '28/2/2024',
		space: 460,
		location: 'https://maps.app.goo.gl/h8UPbm43pGoCqQZo8',
		dimensions: '23م X 20م',
		images: [C29_1, C29_2, C29_3, C29_4],
		id: 59,
		dataId: 'C29',
	},
	{
		title: 'دار سكني ملك صرف',
		address: 'الموصل/الأيمن/17تموز/فرع مول تموز أول فرع عاليمين',
		description: null,
		cords: {
			lat: 36.366944,
			lng: 43.085806,
		},
		price: 450000000,
		rooms: '6+1',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني ',
		contractType: 'ملك صرف',
		date: '28/2/2024',
		space: 398,
		location: 'https://maps.app.goo.gl/1Ud7NXJKgmqBJn119',
		dimensions: '16م X 25م',
		images: [C30_1, C30_2, C30_3, C30_4, C30_5, C30_6],
		id: 60,
		dataId: 'C30',
	},
	{
		title: 'دار سكني زراعي جمعيات',
		address: 'الموصل/حي زيونة مدخل 95/فرع افران المزارع',
		description: null,
		cords: {
			lat: 36.301667,
			lng: 43.184111,
		},
		price: 110000000,
		rooms: '2+1',
		bathrooms: 1,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني ',
		contractType: 'أرض زراعي',
		date: '28/2/2024',
		space: 120,
		location: 'https://maps.app.goo.gl/TX2ncCkSowJw8ofb6',
		dimensions: '6.25مX 19م',
		images: [C31_1, C31_2, C31_3, C31_4, C31_5],
		id: 61,
		dataId: 'C31',
	},
	{
		title: 'دار سكني /تجاري /ملك صرف ',
		address: 'الموصل/حي التحرير/مقابل جامع الزهراء',
		description: null,
		cords: {
			lat: 36.388278,
			lng: 43.205389,
		},
		price: 450000000,
		rooms: 'دار سكني +4 محلات',
		bathrooms: 1,
		offerType: 'BUY',
		propertyType: ['BYT', 'TGY'],
		displayPropType: 'دار سكني تجاري',
		contractType: 'ملك صرف',
		date: '28/2/2024',
		space: 150,
		location: 'https://maps.app.goo.gl/D72T2C1ZmwpKaJJi6',
		dimensions: '15م X 10م',
		images: [C32_1, C32_2, C32_3],
		id: 62,
		dataId: 'C32',
	},
	{
		title: 'دار سكني ملك صرف',
		address: 'الموصل/حي الفلاح الأولى /فرع جامع التقى',
		description: null,
		cords: {
			lat: 36.394222,
			lng: 43.178417,
		},
		price: 135000000,
		rooms: '3+1',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني ',
		contractType: 'ملك صرف',
		date: '28/2/2024',
		space: 100,
		location: 'https://maps.app.goo.gl/RamMoCibgiJNnPXs5',
		dimensions: '5م  X 20م',
		images: [C33_1, C33_2, C33_3, C33_4],
		id: 63,
		dataId: 'C33',
	},
	{
		title: 'دار سكني / وصل بلدية',
		address: 'الموصل/هيما ستي/مقابل جامع يوسف الصديق',
		description: null,
		cords: {
			lat: 36.394333,
			lng: 43.222917,
		},
		price: 155000000,
		rooms: '3+1',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني ',
		contractType: 'وصل بلدية ',
		date: '28/2/2024',
		space: 240,
		location: 'https://maps.app.goo.gl/YVF6BRWtuX9eEb9S6',
		dimensions: '16م X 15م',
		images: [C34_1, C34_2, C34_3],
		id: 64,
		dataId: 'C34',
	},
	{
		title: 'دار تجاري يحتوي على 4 محلات+دار+شقة',
		address: 'الموصل/سوق النبي/دخول فرع مسجد الغفران',
		description: null,
		cords: {
			lat: 36.350778,
			lng: 43.160028,
		},
		price: 1550000000,
		rooms: '4+1',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني تجاري',
		contractType: 'ملك صرف',
		date: '2/3/2024',
		space: 223,
		location: 'https://maps.app.goo.gl/Xv7zp2G7xU3B1Fio7',
		dimensions: 'لايوجد',
		images: [C35_1, C35_2, C35_3],
		id: 65,
		dataId: 'C35',
	},
	{
		title: 'دار سكني',
		address: 'الموصل/حي التعليم الأولى/الشارع الرئيسي/بجانب مكتب دليل للعقار',
		description: null,
		cords: {
			lat: 36.437056,
			lng: 43.117778,
		},
		price: 450000000,
		rooms: '5+1',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني ',
		contractType: 'ملك صرف',
		date: '2/3/2024',
		space: 300,
		location: 'https://maps.app.goo.gl/miWJyNA6xAuTR2kv6',
		dimensions: '12م X 15م',
		images: [C36_1, C36_2, C36_3, C36_4, C36_5],
		id: 66,
		dataId: 'C36',
	},
	{
		title: '3 دور سكنية',
		address: 'الموصل/حي الفلاح الأولى /فرع مقابل صيدلية اللجين/رابع دار',
		description: null,
		cords: {
			lat: 36.392833,
			lng: 43.182389,
		},
		price: 1700000000,
		rooms: '14+3',
		bathrooms: 6,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني ',
		contractType: 'أرض زراعي',
		date: '3/3/2024',
		space: 1300,
		location: 'https://maps.app.goo.gl/x6UfbpQe2UPtZb6n9',
		dimensions: '31م X 41م',
		images: [C37_1, C37_2, C37_3, C37_4],
		id: 67,
		dataId: 'C37',
	},
	{
		title: 'دار سكني',
		address: 'الموصل/حي الصدام/المدخل الأول/مقابل مكتب كرم للحج والعمره',
		description: null,
		cords: {
			lat: 36.383722,
			lng: 43.202278,
		},
		price: 155000000,
		rooms: '2+1',
		bathrooms: 1,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني ',
		contractType: 'ملك صرف',
		date: '3/3/2024',
		space: 200,
		location: 'https://maps.app.goo.gl/6bXTH2TrNrqh1UWw8',
		dimensions: '10م X 20م',
		images: [C38_1, C38_2, C38_3],
		id: 68,
		dataId: 'C38',
	},
	{
		title: 'دار سكني طابقين /ملك صرف',
		address: 'الموصل/الأيمن/حي الصمود/قرب ثانوية المامون للبنات',
		description: null,
		cords: {
			lat: 36.314889,
			lng: 43.114111,
		},
		price: 250000000,
		rooms: '5+1',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني ',
		contractType: 'ملك صرف',
		date: '3/3/2024',
		space: 224,
		location: 'https://maps.app.goo.gl/prfxL7okab7xh5GE6',
		dimensions: '12م X 19م',
		images: [C39_1, C39_2, C39_3, C39_4, C39_5, C39_6, C39_7, C39_8, C39_9],
		id: 69,
		dataId: 'C39',
	},
	{
		title: 'دار سكني تجاري',
		address: 'الموصل/حي الاعلام/سايدين حي البكر/بالقرب من مأكولات العابد',
		description: null,
		cords: {
			lat: 36.359694,
			lng: 43.196833,
		},
		price: 633000000,
		rooms: '7+1',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: ['BYT', 'TGY'],
		displayPropType: 'دار سكني تجاري',
		contractType: 'ملك صرف',
		date: '27/2/2024',
		space: 239,
		location: 'https://maps.app.goo.gl/niqt4vbnubJw8pGQ7',
		dimensions: '8م X 29.5م',
		images: [C40_1, C40_2, C40_3],
		id: 70,
		dataId: 'C40',
	},
	{
		title: 'وصل بلدية ',
		address: 'الموصل/حي الكندي الثالثة/خلف مولده الحدباء',
		description: null,
		cords: {
			lat: 36.424722,
			lng: 43.143389,
		},
		price: 255000000,
		rooms: '5+1',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني ',
		contractType: 'ارض زراعي',
		date: '9/3/2024',
		space: 200,
		location: 'https://maps.app.goo.gl/uujSsRoC4JhCgNTUA',
		dimensions: '12م X 16م',
		images: [C41_1, C41_2, C41_3, C41_4],
		id: 71,
		dataId: 'C41',
	},
	{
		title: 'وصل بلدية ',
		address: 'الموصل/حي الكندي الثالثة/خلف مولده الحدباء',
		description: null,
		cords: {
			lat: 36.424722,
			lng: 43.143389,
		},
		price: 200000000,
		rooms: '3+1',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني ',
		contractType: 'ارض زراعي',
		date: '9/3/2024',
		space: 200,
		location: 'https://maps.app.goo.gl/uujSsRoC4JhCgNTUA',
		dimensions: '12م X 16م',
		images: [C42_1, C42_2, C42_3, C42_4],
		id: 72,
		dataId: 'C42',
	},
	{
		title: 'وصل بلدية ',
		address: 'الموصل/حي الكندي الثالثة/خلف مولده الحدباء',
		description: null,
		cords: {
			lat: 36.424722,
			lng: 43.143389,
		},
		price: 200000000,
		rooms: '3+1',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني ',
		contractType: 'ارض زراعي',
		date: '9/3/2024',
		space: 200,
		location: 'https://maps.app.goo.gl/uujSsRoC4JhCgNTUA',
		dimensions: '12م X 16م',
		images: [C43_1, C43_2, C43_3, C43_4],
		id: 73,
		dataId: 'C43',
	},
	{
		title: 'دار سكني ',
		address: 'موصل حي الصديق قرب جامع الرحمة فرع المجمع الأوربي الثاني',
		description: null,
		cords: {
			lat: 36.396056,
			lng: 43.165222,
		},
		price: 400000000,
		rooms: '6+2',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني ',
		contractType: 'ملك صرف ',
		date: '9/3/2024',
		space: 320,
		location: 'https://maps.app.goo.gl/gSCXLNLaAqFPhdwP7',
		dimensions: '10مX 36م',
		images: [C44_1, C44_2, C44_3, C44_4],
		id: 74,
		dataId: 'C44',
	},
	{
		title: 'دار سكني ',
		address: 'موصل حي الملايين الأولى  خلف جامع الصواف',
		description: null,
		cords: {
			lat: 36.418639,
			lng: 43.097500,
		},
		price: 97000000,
		rooms: '3+1',
		bathrooms: 1,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني هيكل',
		contractType: 'وصل بلدية',
		date: '9/3/2024',
		space: 155,
		location: 'https://maps.app.goo.gl/9pZCs5y6JiyCA8S46',
		dimensions: '8م X  19.37م',
		images: [C45_1, C45_2, C45_3],
		id: 75,
		dataId: 'C45',
	},
	{
		title: 'دار سكني ',
		address: 'موصل جمعية النور خلف مول الهيكل',
		description: null,
		cords: {
			lat: 36.438556,
			lng: 43.109278,
		},
		price: 167000000,
		rooms: '4+2',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني ',
		contractType: 'وصل بلدية',
		date: '6/3/2024',
		space: 160,
		location: 'https://maps.app.goo.gl/6Ug3Zh6ni4Lpa6x48',
		dimensions: '8م X 20م',
		images: [C46_1, C46_2, C46_3],
		id: 76,
		dataId: 'C46',
	},
	{
		title: 'دار سكني هيكل ',
		address: 'موصل حي التربية الرابعة مقابل الجامع',
		description: null,
		cords: {
			lat: 36.433722,
			lng: 43.130083,
		},
		price: 92000000,
		rooms: '4+2',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني ',
		contractType: 'وصل بلدية جمعية التربية الرابعة',
		date: '7/3/2024',
		space: 150,
		location: 'https://maps.app.goo.gl/MtT4RFxERrS2LCfr8',
		dimensions: '7.5م  X   20م',
		images: [C47_1, C47_2, C47_3, C47_4],
		id: 77,
		dataId: 'C47',
	},
	{
		title: 'دار سكني',
		address: 'موصل مزارع الحدباء قرب مولدة سدرة المنتهى',
		description: null,
		cords: {
			lat: 36.412306,
			lng: 43.152889,
		},
		price: 75000000,
		rooms: '2+1',
		bathrooms: 1,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني ',
		contractType: 'وصل بلدية ',
		date: '12/3/2024',
		space: 125,
		location: 'https://maps.app.goo.gl/tnkvS3hHdoUZXCtN6',
		dimensions: '7م X18 م',
		images: [C48_1, C48_2, C48_3],
		id: 78,
		dataId: 'C48',
	},
	{
		title: 'دار سكني',
		address: 'موصل/حي السماح الثانية/ قرب مدرسة الامام الزهيري',
		description: null,
		cords: {
			lat: 36.367306,
			lng: 43.230667,
		},
		price: 100000000,
		rooms: '3+1',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني ',
		contractType: 'ملك صرف',
		date: '18/3/2024',
		space: 100,
		location: 'https://maps.app.goo.gl/y4A7iWr29JaKGpbM7',
		dimensions: '5م X 20م',
		images: [C49_1, C49_2, C49_3, C49_4],
		id: 79,
		dataId: 'C49',
	},
	{
		title: 'دار سكني',
		address: 'موصل /حي الاخاء الثانية /قرب جامع الوارث',
		description: null,
		cords: {
			lat: 36.358278,
			lng: 43.208667,
		},
		price: 85000000,
		rooms: '2+1',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني ',
		contractType: 'وصل بلدية',
		date: '17/3/2024',
		space: 100,
		location: 'https://maps.app.goo.gl/KSs4R4NYBti32axy8',
		dimensions: '6.25م X 16.50م',
		images: [C50_1],
		id: 80,
		dataId: 'C50',
	},
	{
		title: 'دار سكني',
		address: 'موصل /حي يارمجة الشرقية الأولى /قرب سايدين كركوك موصل',
		description: null,
		cords: {
			lat: 36.296583,
			lng: 43.212694,
		},
		price: 180000000,
		rooms: '4+1',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني ',
		contractType: 'ملك صرف',
		date: '20/3/2024',
		space: 200,
		location: 'https://maps.app.goo.gl/TpHVarkqzfbiq9BR8',
		dimensions: '10 م X 20م',
		images: [C51_1, C51_2, C51_3],
		id: 81,
		dataId: 'C51',
	},
	{
		title: 'دار سكني',
		address: 'الموصل /حي المشراق/ خلف مطعم يونس العمر',
		description: null,
		cords: {
			lat: 36.368417,
			lng: 43.190222,
		},
		price: 275000000,
		rooms: '6+1',
		bathrooms: 3,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني ',
		contractType: 'ملك صرف',
		date: '20/3/2024',
		space: 204,
		location: 'https://maps.app.goo.gl/zwd9C5i3V1gaKYTFA',
		dimensions: '12.5 م X16.30 م',
		images: [C52_1, C52_2, C52_3],
		id: 82,
		dataId: 'C52',
	},
	{
		title: 'دار سكني ركن /يحتوي على وصل بلدية',
		address: 'الموصل /جمعية التجارة /مفرق  تلكيف ',
		description: null,
		cords: {
			lat: 36.440944,
			lng: 43.107306,
		},
		price: 70000000,
		rooms: '2+1',
		bathrooms: 1,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني ',
		contractType: 'وصل بلدية/جمعية غرفة التجارة',
		date: '21/3/2024',
		space: 100,
		location: 'https://maps.app.goo.gl/MukHrx4fsXe5DwiU6',
		dimensions: '5م X  20 م',
		images: [C53_1, C53_2, C53_3, C53_4],
		id: 83,
		dataId: 'C53',
	},
	{
		title: 'دار سكني /يحتوي على وصل بلدية',
		address: 'الموصل /مجمع زيونة /بعد سيطرة الشلالات',
		description: null,
		cords: {
			lat: 36.416278,
			lng: 43.201806,
		},
		price: 250000000,
		rooms: '6+1',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'BYT',
		displayPropType: 'دار سكني ',
		contractType: 'وصل بلدية',
		date: '20/3/2024',
		space: 200,
		location: 'https://maps.app.goo.gl/qNPmVBRj6TitZG4c9',
		dimensions: '10م X 20 م',
		images: [C54_1, C54_2],
		id: 84,
		dataId: 'C54',
	},
	{
		title: 'عمارة للبيع',
		address: 'الموصل/الأيمن/شارع الخالد بن الوليد/دورة الأجهزة الدقيقة/مقابل لحم بعجين عشتار',
		description: null,
		cords: {
			lat: 36.340750,
			lng: 43.131417,
		},
		price: 410000000,
		rooms: 'شقة معزولة / 10بانقات 6 بالواجهة و 4 داخل',
		bathrooms: 2,
		offerType: 'BUY',
		propertyType: 'TGY',
		displayPropType: 'عمارة تجارية',
		contractType: 'ملك صرف',
		date: '11/2/2024',
		space: 256,
		location: 'https://maps.app.goo.gl/uJUpkSapX15fDmKX8',
		dimensions: 'م21  مساحة العقار غير منتظم',
		images: [G1_1, G1_2],
		id: 85,
		dataId: 'G1',
	},
	{
		title: 'عمارة ركن ملك صرف',
		address: 'الموصل/الأيمن/وادي حجر/مجاور فلافل بدر/مركز السوق',
		description: null,
		cords: {
			lat: 36.321639,
			lng: 43.126944,
		},
		price: 3000000000,
		rooms: '3 شقق / كل شقة تحتوي على غرفتين',
		bathrooms: 4,
		offerType: 'BUY',
		propertyType: 'TGY',
		displayPropType: 'عمارة تجارية',
		contractType: 'ملك صرف',
		date: '19/2/2024',
		space: 629.5,
		location: 'https://maps.app.goo.gl/VCXnueQphaq3RXJT9',
		dimensions: '35.5م x 34.5م',
		images: [G2_1, G2_2, G2_3, G2_4],
		id: 86,
		dataId: 'G2',
	},
	{
		title: 'عمارة تجارية في بيع المباشر ملك صرف',
		address: 'الموصل/بيع المباشر/ساحة الوكلاء/كراج عبدالرحمن الحداد',
		description: null,
		cords: {
			lat: 36.348972,
			lng: 43.185528,
		},
		price: 2250000000,
		rooms: 'سردابين +شقة+4 محلات',
		bathrooms: 1,
		offerType: 'BUY',
		propertyType: 'TGY',
		displayPropType: 'عمارة تجارية',
		contractType: 'ملك صرف',
		date: '24/2/2024',
		space: 500,
		location: 'https://maps.app.goo.gl/BFaF418L2Jc2qVUp7',
		dimensions: '21م x 24 م',
		images: [G3_1, G3_2, G3_3, G3_4, G3_5],
		id: 87,
		dataId: 'G3',
	},
	{
		title: 'عمارة تجارية للبيع / ملك صرف',
		address: 'الموصل/حي الجزائر/فرع البانزيخانة/بجنب شركة الزرقاء',
		description: null,
		cords: {
			lat: 36.357917,
			lng: 43.169139,
		},
		price: 550000000,
		rooms: '5 محلات + شقتين + مخزن كبير +مخزن صغير ',
		bathrooms: 1,
		offerType: 'BUY',
		propertyType: 'TGY',
		displayPropType: 'عمارة تجارية',
		contractType: 'ملك صرف',
		date: '29/2/2024',
		space: 175.5,
		location: 'https://maps.app.goo.gl/wqcryuHBaeePBmfX9',
		dimensions: '20م X 8.5م',
		images: [G4_1, G4_2, G4_3, G4_4],
		id: 88,
		dataId: 'G4',
	},
	{
		title: 'عمارة تجارية للبيع / ملك صرف',
		address: 'الموصل/سوق نبي/عمارة الفردوس',
		description: null,
		cords: {
			lat: 36.354528,
			lng: 43.159444,
		},
		price: 4851000000,
		rooms: 'محل136 + 10 شقق',
		bathrooms: 10,
		offerType: 'BUY',
		propertyType: 'TGY',
		displayPropType: 'عمارة تجارية',
		contractType: 'ملك صرف',
		date: '25/2/2024',
		space: 539,
		location: 'https://maps.app.goo.gl/wbKkTq5qFT5d1eqw8',
		dimensions: '21.5م X 25م',
		images: [G5_1, G5_2, G5_3, G5_4, G5_5],
		id: 89,
		dataId: 'G5',
	},
	{
		title: 'عمارة تجارية للبيع / ملك صرف',
		address: 'الموصل/ الجانب الأيمن/ النبي شيت /شارع المركز',
		description: null,
		cords: {
			lat: 36.332250,
			lng: 43.135972,
		},
		price: 100000000,
		rooms: '4 محلات',
		bathrooms: 3,
		offerType: 'BUY',
		propertyType: 'TGY',
		displayPropType: 'عمارة تجارية',
		contractType: 'ملك صرف',
		date: '24/3/2024',
		space: 80,
		location: 'https://maps.app.goo.gl/DKGmWu3FMrwzohf18',
		dimensions: '10م X  8 م',
		images: [G6_1, G6_2, G6_3, G6_4],
		id: 90,
		dataId: 'G6',
	},
	{
		title: 'عمارة تجارية للبيع / ملك صرف',
		address: 'الموصل/الأيمن/شارع النبي شيت /شارع السجن/ مقابل المدرسة الكرفانية',
		description: null,
		cords: {
			lat: 36.330694,
			lng: 43.134722,
		},
		price: 150000000,
		rooms: '3 شقق ',
		bathrooms: 3,
		offerType: 'BUY',
		propertyType: 'TGY',
		displayPropType: 'عمارة تجارية',
		contractType: 'ملك صرف',
		date: '24/3/2024',
		space: 82,
		location: 'https://maps.app.goo.gl/1pK8owA1mYad1gZh6',
		dimensions: '7.5مX 11 م',
		images: [G7_1, G7_2, G7_3],
		id: 91,
		dataId: 'G7',
	},
	{
		title: 'بيت للايجار',
		address: 'الموصل/حي البكر/قرب دوره حي البكر',
		description: null,
		cords: {
			lat: 36.365333,
			lng: 43.194222,
		},
		price: 1000000,
		rooms: '5+1',
		bathrooms: 2,
		offerType: 'RENT',
		propertyType: 'BYT',
		displayPropType: 'دار سكني',
		contractType: 'ملك صرف',
		date: '26/2/2024',
		space: 260,
		location: 'https://maps.app.goo.gl/wG584PzworYxJLMs7',
		dimensions: '12م  x 21م',
		images: [I1_1, I1_2, I1_3, I1_4, I1_5],
		id: 92,
		dataId: 'I1',
	},
	{
		title: 'محلات داخل العمارة صالحة للايجار',
		address: 'الموصل/الأيمن/الدواسة شارع المطربين',
		description: null,
		cords: {
			lat: 36.333361,
			lng: 43.137167,
		},
		price: 100000,
		rooms: 'لايوجد',
		bathrooms: 4,
		offerType: 'RENT',
		propertyType: 'MUL',
		displayPropType: 'عمارة تجارية',
		contractType: 'ملك صرف',
		date: '12/2/2024',
		space: 413,
		location: 'https://maps.app.goo.gl/PNvwFCmV812NHPki8',
		dimensions: '2م x 3م لكل محل وهناك محلات أكبر',
		images: [J1_1, J1_2, J1_3, J1_4, J1_5, J1_6, J1_7],
		id: 93,
		dataId: 'J1',
	},
	{
		title: 'عماره تجارية للايجار',
		address: 'الموصل/حي النور/دوره طاهر زيناوه/مجاور كباب عرفان',
		description: null,
		cords: {
			lat: 36.367111,
			lng: 43.188667,
		},
		price: 5000000,
		rooms: 'محلان كبيران',
		bathrooms: 1,
		offerType: 'RENT',
		propertyType: 'EMA',
		displayPropType: 'عمارة تجارية',
		contractType: 'ملك صرف',
		date: '29/2/2024',
		space: 320,
		location: 'https://maps.app.goo.gl/58X3do1HD7uR3n1bA',
		dimensions: '10م X 32م',
		images: [K1_1, K1_2, K1_3, K1_4],
		id: 94,
		dataId: 'K1',
	},
	{
		title: 'عماره تجارية للايجار',
		address: 'الموصل/حي الزهور/مقابل دوره العبادي',
		description: null,
		cords: {
			lat: 36.380972,
			lng: 43.182472,
		},
		price: 15000000,
		rooms: 'محلات كبيرة',
		bathrooms: 'لايوجد',
		offerType: 'RENT',
		propertyType: 'EMA',
		displayPropType: 'محلات كبيرة',
		contractType: 'ملك صرف',
		date: '28/2/2024',
		space: 470,
		location: 'https://maps.app.goo.gl/iz2LAFqtxBEw7vFE8',
		dimensions: 'لايوجد',
		images: [K2_1, K2_2],
		id: 95,
		dataId: 'K2',
	},
	{
		title: 'ارض سكنية ركن شروق تام',
		address: 'موصل/حي الملايين الأولى/مقابل اعداديةنينوى الصناعية ',
		description: null,
		cords: {
			lat: 36.419167,
			lng: 43.106583,
		},
		price: 152000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'أرض سكني',
		contractType: 'بستان ملك صرف',
		date: '18/3/2024',
		space: 202,
		location: 'https://maps.app.goo.gl/rsRDVkQXoHusvi1G7',
		dimensions: '19م X 8م-14م',
		images: [A30_1, A30_2, A30_3],
		id: 96,
		dataId: 'A30',
	},
	{
		title: 'ارض سكنية ',
		address: 'الموصل/حي الملايين الأولى /صعدة الكمرك',
		description: null,
		cords: {
			lat: 36.426083,
			lng: 43.103056,
		},
		price: 86000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'أرض سكني',
		contractType: 'بستان ملك صرف +وصل بلدية',
		date: '18/3/2024',
		space: 200,
		location: 'https://maps.app.goo.gl/k8wQuFSzFowLYJqx6',
		dimensions: '10م X  20م',
		images: [A31_1, A31_2],
		id: 97,
		dataId: 'A31',
	},
	{
		title: 'ارض تجارية على السايدين العام',
		address: 'الموصل /حي الملايين الأولى /قرب الكمرك /مقابل تيم مارت',
		description: null,
		cords: {
			lat: 36.425222,
			lng: 43.105694,
		},
		price: 1687000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'ارض تجارية',
		contractType: 'ملك صرف ',
		date: '20/3/2024',
		space: 1250,
		location: 'https://maps.app.goo.gl/2LYmEfybftAZMw857',
		dimensions: '25م X 50م',
		images: [A32_1, A32_2, A32_3],
		id: 98,
		dataId: 'A32',
	},
	{
		title: 'ارض تجارية على السايدين العام',
		address: 'الموصل /حي الملايين الأولى /قرب الكمرك /مقابل تيم مارت',
		description: null,
		cords: {
			lat: 36.425222,
			lng: 43.105694,
		},
		price: 1687000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'ارض تجارية',
		contractType: 'ملك صرف ',
		date: '20/3/2024',
		space: 1250,
		location: 'https://maps.app.goo.gl/2LYmEfybftAZMw857',
		dimensions: '25م X 50م',
		images: [A33_1, A33_2, A33_3],
		id: 99,
		dataId: 'A33',
	},
	{
		title: 'ارض سكنية',
		address: 'موصل/ حب القادسية الأولى (الشيخكية) /بالقرب من مدرسة البقيع',
		description: null,
		cords: {
			lat: 36.371167,
			lng: 43.195250,
		},
		price: 90000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'ارض سكنية',
		contractType: 'وصل بلدية',
		date: '21/3/2024',
		space: 145,
		location: 'https://maps.app.goo.gl/Xx48UT6udZdf4Dao6',
		dimensions: '7.25 مx 20 م',
		images: [A34_1, A34_2, A34_3],
		id: 100,
		dataId: 'A34',
	},
	{
		title: 'ارض سكنية',
		address: 'الموصل /القدس الثانية /مقابل مجمع الشروق',
		description: null,
		cords: {
			lat: 36.343889,
			lng: 43.241556,
		},
		price: 55000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'ارض سكنية',
		contractType: 'ملك صرف ',
		date: '23/3/2024',
		space: 200,
		location: 'https://maps.app.goo.gl/quv1ZHa2Ag2gSNjU6',
		dimensions: '10 مx 20 م',
		images: [A35_1, A35_2, A35_3,A35_4],
		id: 101,
		dataId: 'A35',
	},
	{
		title: 'ارض سكنية',
		address: 'الموصل/حي الأوائل /شارع 12',
		description: null,
		cords: {
			lat: 36.420528,
			lng: 43.112806,
		},
		price: 230000000,
		rooms: null,
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'SKN',
		displayPropType: 'ارض سكنية',
		contractType: 'وصل بلدية /زراعي بستان ملك صرف',
		date: '23/3/2024',
		space: 200,
		location: 'https://maps.app.goo.gl/AssGTMwK2WCE7EaS7',
		dimensions: '10 مx 20 م',
		images: [A36_1, A36_2, A36_3],
		id: 102,
		dataId: 'A36',
	},
	{
		title: 'محلات تجارية',
		address: 'الموصل/ وادي حجر/ حي الهاتف /مقابل استوديو دجلة',
		description: null,
		cords: {
			lat: 36.322611,
			lng: 43.128639,
		},
		price: 510000000,
		rooms: '13 محل تجاري',
		bathrooms: null,
		offerType: 'BUY',
		propertyType: 'MHL',
		displayPropType: 'محلات تجارية',
		contractType: 'ملك صرف',
		date: '24/3/2024',
		space: 140,
		location: 'https://maps.app.goo.gl/AkSh3TSeduySZwxg9',
		dimensions: '36م X  4م',
		images: [F1_1, F1_2, F1_3, F1_4, F1_5, F1_6, F1_7],
		id: 103,
		dataId: 'F1',
	},
];