import React from 'react';
import OfferCard from '../OfferCard';
import Slider from 'react-slick';
import Houses from '../../media/images/houses.jpg';
import './Carousel.css';

export default function Carousel({ data, size }) {
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		arrows: false,
		className: 'center',
		centerMode: true,
		centerPadding: '60px',
		accessibility: true,
		autoplay: true,
		autoplaySpeed: 2000,
	};

	const settings_mobile = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		className: 'center',
		centerMode: true,
		centerPadding: '0px',
		accessibility: true,
		autoplay: true,
		autoplaySpeed: 2000,
	};

	if (size === 'mobile')
		return (
			<div className="slider-container">
				<Slider {...settings_mobile}>
					{data.map(
						(
							{
								title,
								price,
								rooms,
								space,
								bathrooms,
								date,
								dataId,
							},
							i
						) => (
							<div
								key={`carousel-card-${i}`}
								className="card-container"
							>
								<OfferCard
									price={price}
									space={space}
									bathrooms={bathrooms}
									title={title}
									src={Houses}
									rooms={rooms}
									date={date}
									id={dataId}
								/>
							</div>
						)
					)}
				</Slider>
			</div>
		);

	return (
		<div className="slider-container">
			<Slider {...settings}>
				{data.map(
					(
						{ title, price, rooms, space, bathrooms, date, dataId },
						i
					) => (
						<div
							key={`carousel-card-${i}`}
							className="card-container"
						>
							<OfferCard
								price={price}
								space={space}
								bathrooms={bathrooms}
								title={title}
								src={Houses}
								rooms={rooms}
								date={date}
								id={dataId}
							/>
						</div>
					)
				)}
			</Slider>
		</div>
	);
}
