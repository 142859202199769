import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import {
	GoogleMap,
	useLoadScript,
	Marker,
	InfoWindow,
} from '@react-google-maps/api';
import { MAP_LOCATION_DATA } from '../../constant/data';
import { useSearchParams } from 'react-router-dom';
import HouseMarker from '../../media/svg/house-pin.svg';
import './MapBox.css';

const libraries = ['places'];

const MAPS_API_KEY = 'AIzaSyC4RI_f68Xu2BsjhSmmaE2V7IrvvVsPtF8';

const DEFAULT_CENTER = {
	lat: 36.3802778,
	lng: 43.1347222,
};

function MapBox({ data, single, locationUrl, fullscreen }) {
	const [searchParams, setSearchParams] = useSearchParams({
		selectedOffer: null,
	});

	const persistedLocation = Number(searchParams.get('lat'))
		? {
				lng: Number(searchParams.get('lng')),
				lat: Number(searchParams.get('lat')),
		  }
		: DEFAULT_CENTER;

	const getContainerHeight = () => {
		if (fullscreen) return '100vh';
		if (single) return '500px';
		return '700px';
	};

	const mapContainerStyle = {
		width: '100%',
		height: getContainerHeight(),
	};

	const selectedLocation = MAP_LOCATION_DATA.find(
		({ dataId }) => dataId === searchParams.get('selectedOffer')
	);

	// const getUserLocation = () => {
	// 	const errorHandler = () => {
	// 		console.error('Error finding location');
	// 	};

	// 	const successHandler = (position) => {
	// 		setUserLocation({
	// 			lat: position.coords.latitude,
	// 			lng: position.coords.longitude,
	// 		});
	// 	};

	// 	if (!navigator.geolocation) {
	// 		errorHandler();
	// 		return;
	// 	}

	// 	navigator.geolocation.getCurrentPosition(successHandler, errorHandler);
	// };

	const handleSelectLocation = (location) => {
		setSearchParams((prev) => {
			prev.set('selectedOffer', location?.dataId);
			return prev;
		});
	};

	const handleCloseWindow = () => {
		setSearchParams((prev) => {
			prev.set('selectedOffer', null);
			return prev;
		});
	};

	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: MAPS_API_KEY,
		libraries,
	});

	if (loadError) {
		return <div>Error loading maps</div>;
	}

	if (!isLoaded) {
		return <div>Loading maps</div>;
	}

	if (single) {
		const selectedLocationPoint = single || {};
		return (
			<GoogleMap
				mapContainerStyle={mapContainerStyle}
				zoom={15}
				center={selectedLocationPoint.cords}
			>
				<Marker
					position={selectedLocationPoint?.cords}
					icon={HouseMarker}
				/>

				<InfoWindow
					position={selectedLocationPoint.cords}
					options={{
						pixelOffset: new window.google.maps.Size(1, -20),
					}}
				>
					<a
						className="info-window-maps-link-single"
						target="_blank"
						href={locationUrl}
						rel="noreferrer"
					>
						الموقع على الخريطة
					</a>
				</InfoWindow>
			</GoogleMap>
		);
	}

	return (
		<>
			<div className="map-container">
				<GoogleMap
					mapContainerStyle={mapContainerStyle}
					zoom={14}
					center={persistedLocation}
				>
					{data.map((locationItem, i) => {
						return (
							<div key={`map-marker-${i}`}>
								<Marker
									onClick={() =>
										handleSelectLocation(locationItem)
									}
									position={locationItem.cords}
									icon={HouseMarker}
								/>
								{selectedLocation?.cords !==
									locationItem.cords && (
									<InfoWindow
										position={locationItem.cords}
										options={{
											pixelOffset:
												new window.google.maps.Size(
													1,
													-20
												),
										}}
									>
										<p className="info-wrapper-map-price">{`د ${locationItem.price}`}</p>
									</InfoWindow>
								)}
							</div>
						);
					})}
					{/* {userLocation && !centerCords && (
						<>
							<Marker position={userLocation} />

							<InfoWindow
								position={userLocation}
								options={{
									pixelOffset: new window.google.maps.Size(
										0,
										-40
									),
								}}
							>
								<p className="info-wrapper-map-price">
									You are here
								</p>
							</InfoWindow>
						</>
					)} */}
					{selectedLocation?.cords && (
						<InfoWindow
							position={selectedLocation.cords}
							onCloseClick={handleCloseWindow}
							options={{
								pixelOffset: new window.google.maps.Size(
									1,
									-20
								),
							}}
						>
							<div className="info-window-wrapper">
								<img
									className="info-window-img"
									alt="Houses"
									src={selectedLocation.images[0]}
								/>
								<h4>{selectedLocation.title}</h4>
								<h4>د {selectedLocation.price} :السعر</h4>
								<h4>م{selectedLocation.space} :المساحة</h4>
								<div className="info-window-btn-wrapper">
									<a
										className="info-window-maps-link"
										target="_blank"
										href={selectedLocation.location}
										rel="noreferrer"
									>
										شاهد على الخرائط
									</a>
									<Link
										className="info-window-maps-link"
										rel="noreferrer"
										to={`offers/${selectedLocation.dataId}`}
									>
										التفاصيل
									</Link>
								</div>
							</div>
						</InfoWindow>
					)}
				</GoogleMap>
			</div>
		</>
	);
}

export default memo(MapBox);
