import React from 'react';
import { useParams } from 'react-router-dom';
import Gallery from '../../components/Gallery';
import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';
import MapBox from '../../components/MapBox/MapBox';
import { MAP_LOCATION_DATA } from '../../constant/data';
import Bathroom from '../../media/svg/bathroom.svg';
import OfferType from '../../media/svg/offer-type.svg';
import Calendar from '../../media/svg/calendar.svg';
import Barcode from '../../media/svg/barcode.svg';
import Dimensions from '../../media/svg/dimensions.svg';
import Rooms from '../../media/svg/rooms.svg';
import Pin from '../../media/svg/pin.svg';
import Size from '../../media/svg/size.svg';
import ContractType from '../../media/svg/contract-type.svg';
import Building from '../../media/svg/building.svg';

import './Offer.css';

export default function Offer() {
	const { offerId } = useParams();

	const offerToShow = MAP_LOCATION_DATA.find(
		({ dataId }) => dataId === offerId
	);

	const {
		title,
		offerType,
		contractType,
		displayPropType,
		date,
		location,
		dimensions,
		price,
		rooms,
		space,
		address,
		bathrooms,
		cords,
		images,
		dataId,
	} = offerToShow || {};

	const TABLE_DATA = [
		{
			label: 'العنوان',
			value: address,
			icon: Pin,
		},
		{
			label: 'المساحة',
			value: `${space}م`,
			icon: Size,
		},
		{
			label: 'ابعاد القطعة',
			value: dimensions,
			icon: Dimensions,
		},
		{
			label: 'عدد الغرف',
			value: rooms,
			icon: Rooms,
		},
		{
			label: 'نوع العقار',
			value: displayPropType,
			icon: Building,
		},
		{
			label: 'نوع السند',
			value: contractType,
			icon: ContractType,
		},
		{
			label: 'ايجار | بيع',
			value: offerType === 'BUY' ? 'بيع' : 'ايجار',
			icon: OfferType,
		},
		{
			label: 'عدد الحمامات',
			value: bathrooms,
			icon: Bathroom,
		},
		{
			label: 'تاريخ النشر',
			value: date,
			icon: Calendar,
		},
		{
			label: 'كود العقار',
			value: dataId,
			icon: Barcode,
		},
	];

	const GALLERY_IMAGES = images.map((img) => {
		return { original: img, thumbnail: img };
	});

	return (
		<main className="offer-main-container">
			<Navigation />
			<h2 className="offer-hero-title">تفاصيل العرض</h2>
			<div className="offer-content-wrapper">
				<div>{images && <Gallery images={GALLERY_IMAGES} />}</div>
				<div className="offer-main-details-wrapper">
					<div className="offer-main-price">
						<p>{`دينار ${price}`}</p>
					</div>
				</div>

				<hr />

				<div className="offer-section-wrapper">
					<div className="offer-details">
						<h2 className="offer-title">الوصف</h2>
						<p className="offer-description">{title}</p>
					</div>

					<hr />

					<h2 className="offer-title">الخصائص</h2>
					<div className="offer-table-wrapper">
						<table className="offer-details-table">
							{TABLE_DATA.map(
								({ label, value, icon }) =>
									value && (
										<tr className="table-row">
											<td className="table-cell-value">
												{value}
											</td>
											<td className="table-cell-key">
												<span>{label}</span>
												<img
													className="table-cell-icon"
													alt="Table cell icon"
													src={icon}
												/>
											</td>
										</tr>
									)
							)}
						</table>
					</div>
					<hr />
					<h2 className="offer-title">الموقع</h2>
					<div className="offer-map-box">
						<MapBox single={{ cords }} locationUrl={location} />
					</div>
				</div>
			</div>
			<Footer />
		</main>
	);
}
