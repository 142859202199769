import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../media/svg/logo-white-bg.svg';
import { LINKS } from './Navigation.constants';
import classNames from 'classnames';
import Menu from '../../media/svg/menu.svg';
import Close from '../../media/svg/close-icon.svg';
import './Navigation.css';

export default function Navigation() {
	const { pathname } = useLocation();
	const [menuOpen, setMenuOpen] = useState(false);

	const { search } = useLocation();

	const toggleMenu = () => {
		setMenuOpen((prev) => !prev);
	};

	return (
		<>
			<div className="nav-desktop">
				<div className="nav-container">
					<img className="nav-logo" alt="hello" src={Logo} />
					<div className="nav-links-wrapper">
						{LINKS.map(({ title, url, linkPath }, i) => (
							<Link
								key={`nav-link-${i}`}
								className={classNames('nav-link', {
									'active-nav-link': pathname === linkPath,
								})}
								to={`${linkPath}${search}`}
							>
								{title}
							</Link>
						))}
					</div>
				</div>
			</div>
			<div className="nav-mobile">
				<div className="nav-mobile-wrapper">
					<div
						className={classNames('nav-container', {
							'nav-mobile-container': menuOpen,
						})}
					>
						<img className="nav-logo" alt="Logo" src={Logo} />
						{menuOpen ? (
							<img
								className="nav-menu-icon"
								alt="Menu"
								src={Close}
								onClick={toggleMenu}
							/>
						) : (
							<img
								className="nav-menu-icon"
								alt="Menu"
								src={Menu}
								onClick={toggleMenu}
							/>
						)}
					</div>
					{menuOpen && (
						<div className="nav-links-wrapper-mobile">
							{LINKS.map(({ title, url, linkPath }, i) => (
								<Link
									key={`nav-link-${i}`}
									className={classNames('nav-link', {
										'active-nav-link':
											pathname === linkPath,
									})}
									onClick={toggleMenu}
									to={`${linkPath}${search}`}
								>
									{title}
								</Link>
							))}
						</div>
					)}
				</div>
			</div>
		</>
	);
}
