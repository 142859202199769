import React, { useState, useEffect } from 'react';
import { MAP_LOCATION_DATA } from '../../constant/data';
import FilterAccordion from '../../components/FilterAccordion';
import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer/Footer';
import Slider from '../../components/RangeSlider';
import InputBox from '../../components/InputBox';
import { useSearchParams } from 'react-router-dom';
import CheckboxList from '../../components/CheckboxList';
import MapBox from '../../components/MapBox';
import Toggle from '../../components/Toggle';
import classNames from 'classnames';
import Filter from '../../media/svg/filters.svg';
import Expand from '../../media/svg/expand.svg';
import Shrink from '../../media/svg/shrink.svg';
import Close from '../../media/svg/close-icon-dark.svg';
import './Map.css';

const PRICE_MIN = 0;
const PRICE_MAX = 10000000000;

const SPACE_MIN = 0;
const SPACE_MAX = 2000;

export default function Buy() {
	const [searchParams, setSearchParams] = useSearchParams({
		spaceRangeMax: SPACE_MAX,
		spaceRangeMin: SPACE_MIN,
		priceRangeMax: PRICE_MAX,
		priceRangeMin: PRICE_MIN,
	});

	const OPERATION_BASED_DATA =
		searchParams.get('buyActive') === 'true'
			? MAP_LOCATION_DATA.filter(({ offerType }) => offerType === 'BUY')
			: MAP_LOCATION_DATA.filter(({ offerType }) => offerType === 'RENT');

	const [filteredList, setFilteredList] = useState(OPERATION_BASED_DATA);
	const [searchedOffer, setSearchedOffer] = useState({});
	const [foundOffer, setFoundOffer] = useState(null);
	const [showFilters, setShowFilters] = useState(false);
	const [fullscreenMap, setFullscreenMap] = useState(false);

	const scrollToTop = () => {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	};

	const toggleFilters = () => {
		setShowFilters((prev) => !prev);
		if (showFilters) {
			document.querySelector('body').style.overflowY = 'unset';
			document.body.removeEventListener('touchstart', function (e) {
				e.preventDefault();
			});
		} else {
			scrollToTop();
			document.querySelector('body').style.overflowY = 'hidden';
			document.body.addEventListener(
				'touchstart',
				function (e) {
					e.preventDefault();
				},
				true
			);
		}
	};

	const findOffer = () => {
		const targetOffer = OPERATION_BASED_DATA.find(
			(offer) => offer?.dataId === searchedOffer
		);

		if (targetOffer) {
			setFoundOffer(targetOffer);
		} else {
			setFoundOffer(null);
		}
	};

	const toggleScroll = (block) => {
		if (block) {
			document.querySelector('body').style.overflowY = 'unset';
			document.body.removeEventListener('touchstart', function (e) {
				e.preventDefault();
			});
		} else {
			document.querySelector('body').style.overflowY = 'hidden';
			document.body.addEventListener(
				'touchstart',
				function (e) {
					e.preventDefault();
				},
				true
			);
		}
	};

	const handleExpandMap = () => {
		scrollToTop();
		setFullscreenMap(true);
		toggleScroll(true);
	};

	const handleShrinkMap = () => {
		setFullscreenMap(false);
		toggleScroll(false);
	};

	const handleUpdateInput = (id) => {
		setSearchedOffer(id);
	};

	const setPriceRange = ({ min, max }) => {
		setSearchParams((prev) => {
			prev.set('priceRangeMin', min);
			prev.set('priceRangeMax', max);
			return prev;
		});
	};

	const setSpaceRange = ({ min, max }) => {
		setSearchParams((prev) => {
			prev.set('spaceRangeMin', min);
			prev.set('spaceRangeMax', max);
			return prev;
		});
	};

	useEffect(() => {
		const getPropValue = (propertyCode) => {
			return searchParams.get(propertyCode) === 'true'
				? propertyCode
				: null;
		};

		const activeProperties = [
			getPropValue('SKN'),
			getPropValue('ZRY'),
			getPropValue('TGY'),
			getPropValue('SHQ'),
			getPropValue('MZA'),
			getPropValue('EMA'),
			getPropValue('MHL'),
			getPropValue('OTH'),
		];

		const isPropertyExist = (propCode) => {
			return activeProperties.find(
				(activeProp) => activeProp === propCode
			);
		};

		const priceMax = Number(searchParams.get('priceRangeMax'));
		const priceMin = Number(searchParams.get('priceRangeMin'));

		const spaceMin = Number(searchParams.get('spaceRangeMin'));
		const spaceMax = Number(searchParams.get('spaceRangeMax'));

		const filterByPropertyType = () => {
			const newFilteredOffers = OPERATION_BASED_DATA.filter(
				({ propertyType }) => isPropertyExist(propertyType)
			);
			return newFilteredOffers;
		};

		const propertyFilteredOffers = filterByPropertyType();
		const priceRanged = propertyFilteredOffers.filter(
			({ price }) => price >= priceMin && price <= priceMax
		);
		const filteredListLocal = priceRanged.filter(
			({ space }) => space >= spaceMin && space <= spaceMax
		);
		if (filteredListLocal) setFilteredList(filteredListLocal);
	}, [searchParams]);

	return (
		<main className="main-content-wrapper">
			<Navigation />
			<div className="buy-page-container">
				<div
					className={classNames('buy-page-filters-container', {
						'buy-page-show-filters': showFilters,
					})}
				>
					{showFilters && (
						<button
							className="filters-icon-button"
							onClick={toggleFilters}
						>
							<img alt="icon" className="map-icon" src={Close} />
						</button>
					)}
					<FilterAccordion title="النوع">
						<Toggle />
					</FilterAccordion>
					<FilterAccordion title="النوع">
						<CheckboxList />
					</FilterAccordion>
					<FilterAccordion title="السعر">
						<Slider
							min={PRICE_MIN}
							max={PRICE_MAX}
							value={[
								Number(searchParams.get('priceRangeMin')),
								Number(searchParams.get('priceRangeMax')),
							]}
							handleChange={setPriceRange}
							unit="د"
						/>
					</FilterAccordion>
					<FilterAccordion title="المساحة">
						<Slider
							min={SPACE_MIN}
							max={SPACE_MAX}
							value={[
								Number(searchParams.get('spaceRangeMin')),
								Number(searchParams.get('spaceRangeMax')),
							]}
							handleChange={setSpaceRange}
							unit="م"
						/>
					</FilterAccordion>
				</div>
				<div className="buy-page-results-wrapper">
					<div className="buy-page-search-wrapper">
						<InputBox handleChange={handleUpdateInput} />
						<button onClick={findOffer} className="filters-submit">
							ابحث
						</button>
						<img
							onClick={toggleFilters}
							className="buy-page-filter-icon"
							alt="Filter icon"
							src={Filter}
						/>
					</div>
					<div className="map-container-desktop">
						{!fullscreenMap && (
							<button
								className="map-icon-button-out"
								onClick={handleExpandMap}
							>
								<img
									alt="icon"
									className="map-icon"
									src={Expand}
								/>
							</button>
						)}
						<MapBox
							data={foundOffer ? [foundOffer] : filteredList}
						/>
					</div>

					{fullscreenMap && (
						<div className="map-container-mobile">
							<button
								className="map-icon-button-in"
								onClick={handleShrinkMap}
							>
								<img
									alt="icon"
									className="map-icon"
									src={Shrink}
								/>
							</button>
							<MapBox
								fullscreen
								data={foundOffer ? [foundOffer] : filteredList}
							/>
						</div>
					)}
				</div>
			</div>
			<Footer />
		</main>
	);
}
