import React from 'react';
import Logo from '../../media/svg/logo-white-bg.svg';
import Facebook from '../../media/svg/facebook.svg';
import Instagram from '../../media/svg/instagram.svg';
import Whatsapp from '../../media/svg/whatsapp.svg';
import Telegram from '../../media/svg/telegram.svg';
import Phone from '../../media/svg/phone.svg';
import Email from '../../media/svg/email.svg';
import Location from '../../media/svg/location.svg';
import './Footer.css';

const LOCATION_LINK =
	'https://www.google.com/maps?q=94JM+3VX+%D9%85%D9%83%D8%AA%D8%A8+%D9%88%D8%A7%D8%AC%D9%87%D8%A9+%D8%A7%D9%84%D9%85%D9%88%D8%B5%D9%84+%D9%84%D9%84%D8%B9%D9%82%D8%A7%D8%B1%D8%A7%D8%AA,+%D8%A7%D9%84%D9%85%D9%88%D8%B5%D9%84%D8%8C+%D9%86%D9%8A%D9%86%D9%88%D9%89+%D9%85%D8%AD%D8%A7%D9%81%D8%B8%D8%A9%D8%8C+%D8%A7%D9%84%D8%B9%D8%B1%D8%A7%D9%82&ftid=0x4007eb0029137dc3:0x8b61cbdda5e8758e&entry=gps&lucs=,47071704,47086688,47084304&g_ep=CAISDDYuOTcuMC4zNjAyMBgAIPCqByobLDQ3MDcxNzA0LDQ3MDg2Njg4LDQ3MDg0MzA0QgJUUg%3D%3D&g_st=iw';

export default function Footer() {
	return (
		<div className="footer-container">
			<div className="footer-wrapper">
				<img className="footer-logo" alt="Logo" src={Logo} />

				<div className="footer-contact-box">
					<div className="footer-contact">
						<img
							className="footer-contact-img"
							alt="Phone icon"
							src={Phone}
						/>
						<p className="footer-contact-text">
							<a
								className="footer-contact-link"
								href="tel:+964 770 005 3325"
							>
								+964 770 005 3325
							</a>
						</p>
					</div>
					<div className="footer-contact">
						<img
							className="footer-contact-img"
							alt="Email icon"
							src={Email}
						/>
						<p className="footer-contact-text">
							<a
								className="footer-contact-link"
								href="mailto:mousa.shaheen@mosulgate.com"
							>
								mousa.shaheen@mosulgate.com
							</a>
						</p>
					</div>

					<div className="footer-contact">
						<img
							className="footer-contact-img"
							alt="Location icon"
							src={Location}
						/>
						<a
							className="footer-contact-link"
							target="_blank"
							href={LOCATION_LINK}
							rel="noreferrer"
						>
							<p className="footer-contact-text">
								مكتب واجهة الموصل
							</p>
						</a>
					</div>
				</div>
				<div className="footer-social-text">
					<p>تواصل معنا</p>
					<div className="footer-social-box">
						<a
							target="_blank"
							href="telegram://send?abid=+9647500053325"
							rel="noreferrer"
						>
							<img
								className="footer-social-img"
								alt="Telegram icon"
								src={Telegram}
							/>
						</a>

						<a
							target="_blank"
							href="https://www.facebook.com/people/%D9%88%D8%A7%D8%AC%D9%87%D8%A9-%D8%A7%D9%84%D9%85%D9%88%D8%B5%D9%84/61555626805995/?mibextid=ZbWKwL"
							rel="noreferrer"
						>
							<img
								className="footer-social-img"
								alt="Facebook icon"
								src={Facebook}
							/>
						</a>

						<a
							target="_blank"
							href="whatsapp://send?abid=+9647700053325"
							rel="noreferrer"
						>
							<img
								className="footer-social-img"
								alt="Whatsapp icon"
								src={Whatsapp}
							/>
						</a>

						<a
							target="_blank"
							href="https://www.instagram.com/w_l_iq/?igsh=dGk3eTNwYnRiZHZ4"
							rel="noreferrer"
						>
							<img
								className="footer-social-img"
								alt="Instagram icon"
								src={Instagram}
							/>
						</a>
					</div>
				</div>
			</div>
			<p className="footer-copyright">Copyright © 2024. Mosulgate</p>
		</div>
	);
}
