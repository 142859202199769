import React from 'react';
import Navigation from '../../components/Navigation';
import Hero from '../../components/Hero';
import MapBox from '../../components/MapBox';
import Carousel from '../../components/Carousel';
import Footer from '../../components/Footer';
import Banner from '../../components/Banner';
import { MAP_LOCATION_DATA } from '../../constant/data';
import './Main.css';

export default function Main() {
	return (
		<main className="main-content-wrapper">
			<Navigation />
			<Hero />

			<h2 className="main-map-header">العقارات المتاحة</h2>
			<MapBox data={MAP_LOCATION_DATA} />

			<div className="main-container">
				<h2 className="main-map-header">البيع المستعجل</h2>
				<div className="main-carousel carousel-desktop">
					<Carousel size="desktop" data={MAP_LOCATION_DATA} />
				</div>

				<div className="main-carousel carousel-mobile">
					<Carousel size="mobile" data={MAP_LOCATION_DATA} />
				</div>
			</div>
			<Banner />

			<Footer />
		</main>
	);
}
