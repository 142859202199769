import React from 'react';
import { Link } from 'react-router-dom';
import Calendar from '../../media/svg/calendar.svg';
import Dimensions from '../../media/svg/dimensions.svg';
import Contract from '../../media/svg/contract.svg';
import './OfferCard.css';

export default function OfferCard({
	src,
	title,
	price,
	rooms,
	address,
	id,
	space,
	bathrooms,
	date,
}) {
	return (
		<div className="offer-card-container">
			<div className="offer-card-img-wrapper">
				<img className="offer-card-img" alt="Offer card" src={src} />
			</div>
			<div className="offer-card-details">
				<div className="offer-card-header">
					<h3 className="offer-card-title">{`... ${title.substring(
						0,
						20
					)}`}</h3>
					<h3 className="offer-card-title">{`مليون دينار ${price}`}</h3>
				</div>
				<div className="offer-card-spec-box">
					<div className="offer-card-spec-wrapper">
						<img
							className="offer-card-icon"
							alt="Card details icon"
							src={Dimensions}
						/>
						<p>{`${space} م`}</p>
					</div>
					<div className="offer-card-spec-wrapper">
						<img
							className="offer-card-icon"
							alt="Card details icon"
							src={Calendar}
						/>
						<p>{date}</p>
					</div>
				</div>
				<Link to={`offers/${id}`} className="offer-card-btn">
					المزيد
				</Link>
			</div>
		</div>
	);
}
