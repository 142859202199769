import React from 'react';
import { Link } from 'react-router-dom';

import './Hero.css';

export default function Hero() {
	return (
		<div className="hero-container">
			<div className="hero-wrapper">
				<div className="hero-text-wrapper">
					<h1 className="hero-heading">طريقك الى الأملاك</h1>
					<p className="hero-subheading">
						مع واجهة الموصل اصبح بيع وشراء وايجار وتعقيب الأملاك
						سهلاً
					</p>
					<Link className="hero-button" rel="noreferrer" to="contact">
						تواصل معنا
					</Link>
				</div>
			</div>
		</div>
	);
}
