import React from 'react';
import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';

import './About.css';

export default function About() {
	return (
		<main>
			<Navigation />
			<section className="about-content-wrapper">
				<h2 className="contact-page-header">حول مكتبنا</h2>
				<p className="about-text">
					فريقنا المتميزة في مجال القانون العقاري لا تقدم فقط خدمات
					قانونية عالية المستوى، بل تضيف لذلك لمسة استثنائية في تعقيب
					المعاملات العقارية. نحن نفتخر بقدرتنا على تسيير جميع مراحل
					المعاملات بدقة واحترافية لا مثيل لها. فريق تعقيب المعاملات
					لدينا متخصص في تسهيل وتنظيم كل جانب من جوانب الصفقات
					العقارية. نقوم بتحليل الوثائق بدقة، وضمان امتثال جميع
					الإجراءات للمعايير القانونية. نضمن أن تكون كل خطوة في
					المعاملة دقيقة وفعّالة، ساهمت في تحقيق أهداف ورغبات العميل.
					مهمتنا لا تقتصر على تقديم النصائح القانونية فقط ، بل نسعى
					جاهدين لتقديم تجربة متكاملة للعميل، حيث نضيف القيمة والتميز
					في جميع جوانب التعامل مع المعاملات العقارية بمهنية وتفانٍ .
					و يتميز مكتبنا بتعامله مع فريق قانوني من المحامين متمرس
					ومختص وبكفاءة عالية في مجال العقارات ونضمن لك من خلال فريقنا
					. 1.عدم تحصيل أية اموال من الزبون إلا بعد إتمام عملية البيع
					او الشراء. 2.يمكننا ايضا من خلال هذا الفريق انجاز كل
					المعاملات بأقل وقت و أقل سعر ممكن . 3. يقدم فريقنا
					الاستشارات القانونية التي تخص العقارات . 4.الفريق القانوني
					يقوم بإجراء جميع المعاملات التي تخص العقارات إن كانت سكنية
					زراعية او تجارية او اي نوع اخر من العقارات فإن إيجاد حلول
					لهذه المشاكل كلها يقع على عاتق اللجنة القانونية. 5. تسهيل
					الاجراءات القانونية التي تخص العقارات . من بيع وشراء العقار
					وعقود الايجارات و بالاضافة الى كل الاجراءات القانونية
					المتعلقة بالعقار (معاملات البيع , الانتقال , تصحيح الجنس ,
					تصحيح الاسم , تصحيح المساحة , تصحيح الاعتبار , الافرازات و
					قرار التمليك ) أضافة الى الانواع الاخرى من المعاملات التي لم
					يتم ذكرها . 6.المكتب هو الوسيط بين المحامي والزبون لتسهيل
					سرعة المعاملة .لا يوجد تواصل بين الزبون والمحامي الا عن طريق
					المكتب فقط .
					<br />
					في النهاية ما عليك عزيزنا العميل سوى القدوم الى مكتبنا
					واعطاءنا اشارة الموافقة للتعامل معنا ليذهب فريق التصوير
					خاصتنا الى موقعك ويقوم بتزويدنا بالصور والمعلومات الخاصة
					بعقارك لنقوم من جهتنا بتضمينها داخل موقعنا.وفي حال عدم قدرتك
					على المجيء إلينا فاتصالك بمكتبنا يكفي لنأتي نحن اليك ونقوم
					بما يلزم.
				</p>
			</section>
			<Footer />
		</main>
	);
}
