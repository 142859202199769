import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PROPERTY_TYPE } from '../../constant/drop-down';
import './CheckboxList.css';

export default function CheckboxList() {
	const [searchParams, setSearchParams] = useSearchParams({
	});

	const updateFilters = (e) => {
		const { value, checked } = e.target;
		setSearchParams((prev) => {
			prev.set(value, checked);
			return prev;
		});
	};

	useEffect(() => {
	  const ALL_PROPERTY_TYPES = [
		searchParams.get('SKN'),
		searchParams.get('ZRY'),
		searchParams.get('TGY'),
		searchParams.get('SHQ'),
		searchParams.get('BYT'),
		searchParams.get('MZA'),
		searchParams.get('EMA'),
		searchParams.get('MHL'),
		searchParams.get('OTH'),
	  ];

	  const initialLoad = ALL_PROPERTY_TYPES.find(propertyType => propertyType === null);

	  if(initialLoad === null) {
		setSearchParams((prev) => {
			prev.set('buyActive', true);
			prev.set('SKN', true);
			prev.set('ZRY', true);
			prev.set('TGY', true);
			prev.set('SHQ', true);
			prev.set('BYT', true);
			prev.set('MZA', true);
			prev.set('EMA', true);
			prev.set('MHL', true);
			prev.set('OTH', true);
			return prev;
		});
	  }
	}, []);
	

	const isPropertyActive = (propertyCode) => {
		return searchParams.get(propertyCode) === 'true';
	};

	return (
		<div className="checkbox-list-wrapper">
			{PROPERTY_TYPE.map(({ title, code, id }, i) => (
				<form key={id}>
					<label className="checkbox-container">
						{title}
						<input
							type="checkbox"
							value={code}
							onChange={updateFilters}
							id="checkbox-list-input"
							checked={isPropertyActive(code)}
						/>
						<span className="checkbox-checkmark"></span>
					</label>
				</form>
			))}
		</div>
	);
}
