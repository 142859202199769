export const LINKS = [
	{
		title: 'الخريطة',
		linkPath: '/map',
	},
	{
		title: 'ايجار',
		linkPath: '/rent',
	},
	{
		title: 'شراء',
		linkPath: '/buy',
	},
	{
		title: 'التواصل',
		linkPath: '/contact',
	},
	{
		title: 'حول مكتبنا',
		linkPath: '/about',
	},
	{
		title: 'الرئيسية',
		linkPath: '/',
	},
];
