import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import './Toggle.css';

export default function Toggle() {
	const [searchParams, setSearchParams] = useSearchParams({
		buyActive: true,
	});

	const buyInputActive = searchParams.get('buyActive') === 'true';
	const rentInputActive = searchParams.get('buyActive') === 'false';

	const onChange = (value) => {
		setSearchParams((prev) => {
			prev.set('buyActive', value);
			return prev;
		});
	};

	return (
		<form className="toggle-wrapper">
			<div className="toggle-input-wrapper">
				<input
					name="operation-type"
					id="buy"
					value="Buy"
					type="radio"
					checked={buyInputActive}
					onChange={() => onChange(true)}
				/>
				<label>شراء</label>
			</div>

			<div className="toggle-input-wrapper">
				<input
					name="operation-type"
					id="rent"
					value="Rent"
					type="radio"
					checked={rentInputActive}
					onChange={() => onChange(false)}
				/>
				<label>ايجار</label>
			</div>
		</form>
	);
}
