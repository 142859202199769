import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Main from './pages/Main';
import Map from './pages/Map';
import Buy from './pages/Buy';
import Rent from './pages/Rent';
import About from './pages/About';
import Contact from './pages/Contact';
import Offer from './pages/Offer';

import './index.css';

export const router = createBrowserRouter([
	{
		path: '/',
		element: <Main />,
	},
	{
		path: 'offers/:offerId',
		element: <Offer />,
	},
	{
		path: '/buy',
		element: <Buy />,
	},
	{
		path: 'buy/offers/:offerId',
		element: <Offer />,
	},
	{
		path: '/contact',
		element: <Contact />,
	},
	{
		path: '/about',
		element: <About />,
	},
	{
		path: '/rent',
		element: <Rent />,
	},
	{
		path: 'rent/offers/:offerId',
		element: <Offer />,
	},
	{
		path: '/map',
		element: <Map />,
	},
	{
		path: 'map/offers/:offerId',
		element: <Offer />,
	},
]);

const root = createRoot(document.getElementById('root'));

root.render(<RouterProvider router={router} />);
