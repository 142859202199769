import React from 'react';
import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';
import MapBox from '../../components/MapBox/MapBox';
import { OFFICE_CORDS } from '../../constant/data';

import './Contact.css';

export default function Contact() {
	return (
		<main className="contact-page-container">
			<Navigation />
			<div className="contact-content-wrapper">
				<h2 className="contact-page-header">التواصل</h2>

				<div className="contact-boxes-wrapper">
					<div className="contact-box">
						<h3 className="contact-box-title">مواقع التواصل</h3>

						<a
							className="contact-box-subtitle"
							href="mailto:mousa.shaheen@mosulgate.com"
						>
							mousa.shaheen@mosulgate.com
						</a>

						<a
							className="contact-box-subtitle"
							target="_blank"
							href="https://www.instagram.com/w_l_iq/?igsh=dGk3eTNwYnRiZHZ4"
							rel="noreferrer"
						>
							Instagram: w_l_iq
						</a>
					</div>
					<div className="contact-box">
						<h3 className="contact-box-title">عنوان مكتبنا</h3>
						<p className="contact-box-subtitle">
							94JM+3VX, Mosul, Nineveh Governorate, Iraq
						</p>
					</div>
					<div className="contact-box">
						<h3 className="contact-box-title">عبر الهاتف</h3>
						<a
							className="contact-box-subtitle"
							target="_blank"
							href="whatsapp://send?abid=+964 770 005 3325"
							rel="noreferrer"
						>
							+964 770 005 3325 Whatsapp
						</a>
						<a
							className="contact-box-subtitle"
							target="_blank"
							href="telegram://send?abid=+964 750 005 3325"
							rel="noreferrer"
						>
							+9647500053325 Telegram
						</a>
					</div>
				</div>

				<div className="contact-map-box">
					<MapBox single={{ cords: OFFICE_CORDS }} />
				</div>
			</div>
			<Footer />
		</main>
	);
}
