import React from 'react';
import Guarantee from '../../media/svg/guarantee.svg';
import Certified from '../../media/svg/certified.svg';
import Connections from '../../media/svg/connections.svg';
import './Banner.css';

export default function Banner() {
	return (
		<section className="banner-container">
			<h1 className="banner-header">مميزات مكتبنا</h1>
			<div className="banner-boxes-container">
				<div className="banner-box">
					<img
						src={Certified}
						alt="Banner icon"
						className="banner-icon"
					/>
					<h3>الاحترافية</h3>
					<p>
						يتميز مكتبنا بتعامله مع فريق قانوني من المحامين متمرس
						ومختص وبكفاءة عالية في مجال العقارات ونضمن لك من خلال
						فريقنا
					</p>
				</div>
				<div className="banner-box">
					<img
						src={Guarantee}
						alt="Banner icon"
						className="banner-icon"
					/>
					<h3>الضمان</h3>
					<p>
						عدم تحصيل أية اموال من الزبون إلا بعد إتمام عملية البيع
						او الشراء
					</p>
				</div>
				<div className="banner-box">
					<img
						src={Connections}
						alt="Banner icon"
						className="banner-icon"
					/>
					<h3>السلاسة</h3>
					<p>
						تسهيل الاجراءات القانونية التي تخص العقارات . من بيع
						وشراء العقار وعقود الايجارات و بالاضافة الى كل الاجراءات
						القانونية المتعلقة بالعقار
					</p>
				</div>
			</div>
		</section>
	);
}
