import React from 'react';
import './InputBox.css';

export default function InputBox({ handleChange }) {
	const updateInputValue = (e) => {
		handleChange(e.target.value);
	};
	return (
		<input
			onChange={updateInputValue}
			placeholder="Offer ID"
			className="input-box-field"
		/>
	);
}
